import jwt_decode from "jwt-decode";
const moment = require("moment");

export const isAuthenticated = () => {
    const token = getToken();
    const isValid =
        token &&
        moment().isBefore(moment(jwt_decode(token).tokenObj.tokenExpiration));

    if (!isValid) {
        logout();
    }

    return isValid;
};

export const logout = () => {
    localStorage.removeItem("JS_KEY");
    window.location.href = '/login';
};

export const isOnlyUser = () => {
    const token = getToken();
    if (token && isAuthenticated()) {
        return jwt_decode(token).tokenObj.userType === "user";
    }
    return false;
};

export const isAdmin = () => {
    const token = getToken();
    if (token && isAuthenticated()) {
        return jwt_decode(token).tokenObj.userType === "admin";
    }
    return false;
};

export const isAdminAdmin = () => {
    const token = getToken();
    if (token && isAuthenticated() && isAdmin()) {
        return jwt_decode(token).tokenObj.role === "admin";
    }
    return false;
};

export const getToken = () => {
    return localStorage.getItem("JS_KEY") || null;
};

export const decodeToken = (token) => {
    if (
        token &&
        moment().isBefore(moment(jwt_decode(token).tokenObj.tokenExpiration))
    ) {
        return jwt_decode(token);
    }
    return null;
};