import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import FormLogin from "../../components/Forms/login.js";
import Loading from "../../components/Loading";

function Login() {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.title = "JS Studio de Dança - Login";
        if (localStorage.getItem("JS_KEY")) {
            window.location.href = "/dashboard";
        }
    }, []);

    return (
        <div>
            <Header />
            <div className="bg-color-primary padding-container">
                <Container className="">
                    <Row>
                        <Col xs={12}>
                            <FormLogin setIsLoading={setIsLoading} />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs={12}>
                            <p className="color-white text-center">Ainda não possui cadastro? <strong className="color-yellow-dark text-uppercase"><a href="/cadastro">criar minha conta!</a></strong></p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
            <Loading show={isLoading} />
        </div>
    );
}

export default Login;
