import React, { useState, useEffect } from "react";

function BackgroundImageSwitcher() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [nextImageIndex, setNextImageIndex] = useState(1); 

    useEffect(() => {
        const interval = setInterval(() => {
            setNextImageIndex((currentImageIndex + 1) % 7);
            setTimeout(() => {
                setCurrentImageIndex((currentImageIndex + 1) % 7);
            }, 1000);
        }, 3500);

        return () => clearInterval(interval);
    }, [currentImageIndex]);

    return (
        <div className="box-bg h-100">
            {[...Array(7).keys()].map(i => (
                <div
                    key={i}
                    className={`box-img img-bg-0${i+1} quotes ${currentImageIndex === i ? "active" : nextImageIndex === i ? "next" : ""}`}
                ></div>
            ))}
        </div>
    );
};

export default BackgroundImageSwitcher;
