import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import api from "../../config/api";
import Sidebar from "../../components/Sidebar";
import Loading from "../../components/Loading";
import WidgetNumber from "../../components/WidgetNumber";

function Dashboard() {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    document.title = "JS Studio de Dança - Dashboard";
    getData();
  }, []);

  const getData = async () => {
    setLoad(true);
    await api
      .get(`/dashboard/home`)
      .then((response) => {
        setData(response.data);
        setLoad(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Sidebar pageName="Dashboard" pageUrl="/dashboard">
        <Container fluid>
          <Row className="mt-2 mb-5">
            <h3>Usuários</h3>
            <Col xs={12} md={3} className="mb-3 mb-md-0">
              <WidgetNumber
                title="Usuários Ativos"
                link="/dashboard/usuarios"
                value={data.usuarios_ativos}
                icon={"local_activity"}
              />
            </Col>
            <Col xs={12} md={3} className="mb-3 mb-md-0">
              <WidgetNumber
                title="Usuários Inativos"
                link="/dashboard/usuarios"
                value={data.usuarios_inativos}
                icon={"people"}
              />
            </Col>
          </Row>
          <Row className="mt-2 mb-5">
            <h3>Eventos</h3>
            <Col xs={12} md={3} className="mb-3 mb-md-0">
              <WidgetNumber
                title="Eventos Ativos"
                link="/dashboard/eventos"
                value={data.eventos_ativos}
                icon={"local_activity"}
              />
            </Col>
            <Col xs={12} md={3} className="mb-3 mb-md-0">
              <WidgetNumber
                title="Eventos Inativos"
                link="/dashboard/eventos"
                value={data.eventos_inativos}
                icon={"people"}
              />
            </Col>
          </Row>
          {data.eventsOrders &&
            data.eventsOrders.map((event, index) => (
              <Row className="mb-5" key={index}>
                <h3>{event.nome}</h3>
                <Col xs={12} md={3} className="mb-3 mb-md-0">
                  <WidgetNumber
                    title="Pedidos Aprovados"
                    link="/dashboard/pedidos"
                    value={event.aprovadas}
                    icon={"check_circle"}
                  />
                </Col>
                <Col xs={12} md={3} className="mb-3 mb-md-0">
                  <WidgetNumber
                    title="Pedidos Pendentes"
                    link="/dashboard/pedidos"
                    value={event.pendentes}
                    icon={"pending"}
                  />
                </Col>
              </Row>
            ))}
        </Container>
      </Sidebar>
      <Loading show={load} />
    </>
  );
}

export default Dashboard;
