import React, { useState } from "react";
import { Row, Col, Card, Form, Alert } from "react-bootstrap";
import Swal from "sweetalert2";
import { ButtonPrimary } from "../Buttons/index.js";
import api from "../../config/api.js";

function FormSignup({ setIsLoading }) {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [minor, setMinor] = useState(false);
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

    const [user, setUser] = useState({
        fullName: "",
        cpf: "",
        password: "",
        gender: "",
        birthDate: "",
        cellphone: "",
        phone: "",
        email: "",
        address: {
            street: "",
            number: "",
            neighborhood: "",
            city: "",
            uf: "",
            postalCode: "",
        },
    });

    const startSignUpProcess = () => {
        setError(false);
        setErrorMessage("");
        setIsLoading(true);
    };

    const handleInputChange = async (event) => {
        const { name, value } = event.target;

        if (name === "postalCode") {
            setPostalCode(value);
            if (value.length === 8) {
                setIsLoading(true);
                try {
                    const response = await api.get(`/config/cep/${value}`);
                    if (!response.data.erro) {
                        setUser((prevUser) => ({
                            ...prevUser,
                            address: {
                                ...prevUser.address,
                                street: response.data.logradouro,
                                neighborhood: response.data.bairro,
                                city: response.data.localidade,
                                uf: response.data.uf,
                                postalCode: value,
                            },
                        }));
                    }
                } catch (error) {
                    console.error("Erro ao buscar CEP:", error);
                }
                setIsLoading(false);
            } else if (value.length < 8) {
                setUser((prevUser) => ({
                    ...prevUser,
                    address: {
                        ...prevUser.address,
                        street: "",
                        neighborhood: "",
                        city: "",
                        uf: "",
                    },
                }));
            }
        } else if (name === "birthDate") {
            setUser((prevUser) => ({
                ...prevUser,
                [name]: value,
            }));

            const today = new Date();
            const birthDate = new Date(value);
            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();

            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            if (age < 18) {
                setMinor(true);
            } else {
                setMinor(false);
            }
        } else if (
            ["street", "number", "neighborhood", "city", "uf"].includes(name)
        ) {
            setUser((prevUser) => ({
                ...prevUser,
                address: {
                    ...prevUser.address,
                    [name]: value,
                },
            }));
        } else {
            setUser((prevUser) => ({
                ...prevUser,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!hasAcceptedTerms) {
            setError(true);
            setErrorMessage(
                "Você deve aceitar os termos e condições para se registrar."
            );
            return;
        };

        startSignUpProcess();

        await api
            .post("/users", user)
            .then((res) => {
                setIsLoading(false);
                Swal.fire({
                    icon: "success",
                    title: "Parabéns!",
                    text: "Seu cadastro foi feito com sucesso! Agora faça o login na próxima página.",
                }).then(() => {
                    window.location.href = "/login";
                });
            })
            .catch((err) => {
                setIsLoading(false);
                setError(true);
                setErrorMessage(err.response.data.error);
            });
    };

    return (
        <Card className="rounded-0 card-form">
            <Row className="m-0">
                <Col xs={12} className="padding-card">
                    <h2 className="text-uppercase font-semi-bold mb-0">
                        Não possui cadastro?
                    </h2>
                    <p className="font-light color-gray mb-4">
                        Digite suas informações e crie sua conta.
                    </p>
                    <Form onSubmit={handleSubmit}>
                        <h4 className="text-center mb-4 font-semi-bold">
                            Dados Pessoais
                        </h4>
                        <Row className="mb-0 mb-md-3">
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3 mb-md-2">
                                    <Form.Label className="text-uppercase">
                                        Nome Completo
                                        <sup className="ms-1 text-danger fw-bold">
                                            *
                                        </sup>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="fullName"
                                        placeholder="Digite seu Nome"
                                        onChange={handleInputChange}
                                        value={user.fullName}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3 mb-md-2">
                                    <Form.Label className="text-uppercase">
                                        CPF
                                        <sup className="ms-1 text-danger fw-bold">
                                            *
                                        </sup>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="cpf"
                                        placeholder="Digite seu CPF"
                                        onChange={handleInputChange}
                                        value={user.cpf}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-0 mb-md-3">
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3 mb-md-2">
                                    <Form.Label className="text-uppercase">
                                        E-mail
                                        <sup className="ms-1 text-danger fw-bold">
                                            *
                                        </sup>
                                    </Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        placeholder="Digite seu E-MAIL"
                                        onChange={handleInputChange}
                                        value={user.email}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3 mb-md-2">
                                    <Form.Label className="text-uppercase">
                                        Senha
                                        <sup className="ms-1 text-danger fw-bold">
                                            *
                                        </sup>
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="password"
                                        placeholder="Digite sua Senha"
                                        onChange={handleInputChange}
                                        value={user.password}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-0 mb-md-3">
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3 mb-md-2">
                                    <Form.Label className="text-uppercase">
                                        Gênero
                                        <sup className="ms-1 text-danger fw-bold">
                                            *
                                        </sup>
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="gender"
                                        onChange={handleInputChange}
                                        value={user.gender}
                                        required
                                    >
                                        <option value="" disabled>
                                            Selecione seu gênero
                                        </option>
                                        {[
                                            {
                                                name: "Masculino",
                                                value: "masculino",
                                            },
                                            {
                                                name: "Feminino",
                                                value: "feminino",
                                            },
                                            { name: "Outro", value: "outro" },
                                        ].map((item, index) => (
                                            <option
                                                value={item.value}
                                                key={index}
                                            >
                                                {item.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3 mb-md-2">
                                    <Form.Label className="text-uppercase">
                                        Data de Nascimento
                                        <sup className="ms-1 text-danger fw-bold">
                                            *
                                        </sup>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="birthDate"
                                        placeholder="Padrão DD/MM/AAAA"
                                        onChange={handleInputChange}
                                        value={user.birthDate}
                                        max={
                                            new Date()
                                                .toISOString()
                                                .split("T")[0]
                                        }
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-0 mb-md-3">
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3 mb-md-2">
                                    <Form.Label className="text-uppercase">
                                        Telefone
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phone"
                                        placeholder="Digite seu Telefone"
                                        onChange={handleInputChange}
                                        value={user.phone}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3 mb-md-2">
                                    <Form.Label className="text-uppercase">
                                        Celular
                                        <sup className="ms-1 text-danger fw-bold">
                                            *
                                        </sup>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="cellphone"
                                        placeholder="Digite seu Celular"
                                        onChange={handleInputChange}
                                        value={user.cellphone}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <h4 className="text-center mb-4 font-semi-bold">
                            Endereço
                        </h4>
                        <Row className="mb-0 mb-md-3">
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3 mb-md-2">
                                    <Form.Label className="text-uppercase">
                                        CEP
                                        <sup className="ms-1 text-danger fw-bold">
                                            *
                                        </sup>
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="postalCode"
                                        placeholder="Digite seu CEP"
                                        onChange={handleInputChange}
                                        value={postalCode}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3 mb-md-2">
                                    <Form.Label className="text-uppercase">
                                        Rua
                                        <sup className="ms-1 text-danger fw-bold">
                                            *
                                        </sup>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="street"
                                        placeholder="Digite a Rua"
                                        onChange={handleInputChange}
                                        value={user.address.street}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-0 mb-md-3">
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3 mb-md-2">
                                    <Form.Label className="text-uppercase">
                                        Número
                                        <sup className="ms-1 text-danger fw-bold">
                                            *
                                        </sup>
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="number"
                                        placeholder="Digite o Número"
                                        onChange={handleInputChange}
                                        value={user.address.number}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3 mb-md-2">
                                    <Form.Label className="text-uppercase">
                                        Bairro
                                        <sup className="ms-1 text-danger fw-bold">
                                            *
                                        </sup>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="neighborhood"
                                        placeholder="Digite o Bairro"
                                        onChange={handleInputChange}
                                        value={user.address.neighborhood}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-0 mb-md-3">
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3 mb-md-2">
                                    <Form.Label className="text-uppercase">
                                        Cidade
                                        <sup className="ms-1 text-danger fw-bold">
                                            *
                                        </sup>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="city"
                                        placeholder="Digite a Cidade"
                                        onChange={handleInputChange}
                                        value={user.address.city}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3 mb-md-2">
                                    <Form.Label className="text-uppercase">
                                        Estado
                                        <sup className="ms-1 text-danger fw-bold">
                                            *
                                        </sup>
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="uf"
                                        onChange={handleInputChange}
                                        value={user.address.uf}
                                        required
                                    >
                                        <option value="" disabled>
                                            Selecione o Estado
                                        </option>
                                        {[
                                            "AC",
                                            "AL",
                                            "AP",
                                            "AM",
                                            "BA",
                                            "CE",
                                            "DF",
                                            "ES",
                                            "GO",
                                            "MA",
                                            "MT",
                                            "MS",
                                            "MG",
                                            "PA",
                                            "PB",
                                            "PR",
                                            "PE",
                                            "PI",
                                            "RJ",
                                            "RN",
                                            "RS",
                                            "RO",
                                            "RR",
                                            "SC",
                                            "SP",
                                            "SE",
                                            "TO",
                                        ].map((item, index) => (
                                            <option key={index} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        {minor && (
                            <>
                                <h4 className="text-center mb-4 font-semi-bold">
                                    Dados de Responsável
                                </h4>
                                <Row className="mb-0 mb-md-3">
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3 mb-md-2">
                                            <Form.Label className="text-uppercase">
                                                Nome Completo - Responsável
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="guardianName"
                                                placeholder="Digite o Nome"
                                                onChange={handleInputChange}
                                                value={user.guardianName}
                                                required={minor}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3 mb-md-2">
                                            <Form.Label className="text-uppercase">
                                                Telefone - Responsável
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="guardianPhone"
                                                placeholder="Digite o Telefone"
                                                onChange={handleInputChange}
                                                value={user.guardianPhone}
                                                required={minor}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>
                        )}
                        <Row className="mb-0 mb-md-3">
                            <small className="color-light-gray">
                                Os campos marcados com um
                                <strong className="ms-1 text-danger fw-bold">
                                    *
                                </strong>{" "}
                                são de preenchimento obrigatório. Não se esqueça
                                de preenchê-los.<br />
                                Certifique-se de revisar e confirmar as informações inseridas no formulário a cima, garantindo que estejam corretas e verdadeiras.
                            </small>
                            <Col
                                xs={12}
                                className="d-flex justify-content-start mt-4"
                            >
                                <Form.Group className="mb-2">
                                    <Form.Check
                                        type="checkbox"
                                        label={
                                            <span>
                                                Ao marcar esta opção, você
                                                concorda com os{" "}
                                                <a
                                                    href="https://jsstudiodedanca.com.br/inscricao-5-mostra-js-studio/politica-privacidade.html"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <strong>
                                                        Termos & Condições
                                                    </strong>
                                                </a>{" "}
                                                da plataforma e seus eventos.
                                            </span>
                                        }
                                        onChange={(e) =>
                                            setHasAcceptedTerms(
                                                e.target.checked
                                            )
                                        }
                                        checked={hasAcceptedTerms}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                xs={12}
                                className="d-flex justify-content-center justify-content-md-end"
                            >
                                <ButtonPrimary
                                    type="submit"
                                    btnText="CRIAR MINHA CONTA"
                                />
                            </Col>
                            {error && (
                                <Alert
                                    variant={"danger"}
                                    className="mt-3 mb-0 text-center small"
                                >
                                    <strong>{errorMessage}</strong>
                                </Alert>
                            )}
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Card>
    );
}

export default FormSignup;
