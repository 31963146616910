import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";
import {
    isAdmin,
    isAdminAdmin,
    isOnlyUser,
    getToken
} from "./config/auth";

// Pages
// import Event from "./pages/Evento";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Home from "./pages/Home";
import FAQSection from "./pages/FAQ";
import Politica from "./pages/Politicas";

import PasswordReset from "./pages/PasswordReset";

// Autenticado
import Dashboard from "./pages/Dashboard";
import AdminUsers from "./pages/Dashboard/AdminUsers";
import Users from "./pages/Dashboard/Users";
import Events from "./pages/Dashboard/Events";
import EventsUsers from "./pages/Dashboard/_client/Events";
import MyEventsUsers from "./pages/Dashboard/_client/MyEvents";
import Orders from "./pages/Dashboard/Orders";

function RedirectToHome() {
    return <Navigate to="/" />;
};

function RedirectToAnotherSite(data) {
    window.location.replace(data.url);
}

function OnlyUserRoute({ element }) {
    if (isOnlyUser()) {
        return element;
    }
    if(getToken()) {
        return <Navigate to="/dashboard" />;
    } else {
        return <Navigate to="/login" />;
    }
}

function AdminRoute({ element }) {
    if (isAdmin()) {
        return element;
    }
    return <Navigate to="/dashboard/eventos-usuarios" />;
}

function AdminAdminRoute({ element }) {
    if (isAdminAdmin()) {
        return element;
    }
    if (isAdmin() && !isAdminAdmin()) {
        return <Navigate to="/dashboard" />;
    }
    return <Navigate to="/dashboard/eventos-usuarios" />;
}

const RootElement = () => (
    <Router>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/cadastro" element={<Signup />} />
            <Route path="/alteracao-senha/:hash" element={<PasswordReset />} />
            <Route path="/perguntas-frequentes" element={<FAQSection />} />
            <Route path="/politica-de-privacidade" element={<Politica />} />

            {/* AUTENTICADO */}
            <Route
                path="/dashboard"
                element={<AdminRoute element={<Dashboard />} />}
            />
            <Route
                path="/dashboard/admin-usuarios"
                element={<AdminAdminRoute element={<AdminUsers />} />}
            />
            <Route
                path="/dashboard/usuarios"
                element={<AdminRoute element={<Users />} />}
            />
            <Route
                path="/dashboard/eventos"
                element={<AdminRoute element={<Events />} />}
            />
            <Route
                path="/dashboard/pedidos"
                element={<AdminRoute element={<Orders />} />}
            />
            <Route
                path="/dashboard/eventos-usuarios"
                element={<OnlyUserRoute element={<EventsUsers />} />}
            />
            <Route
                path="/dashboard/meus-eventos"
                element={<OnlyUserRoute element={<MyEventsUsers />} />}
            />

            {/* 404 */}
            <Route path="*" element={<RedirectToHome />} />
        </Routes>
    </Router>
);

export default RootElement;
