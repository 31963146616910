import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import FormSignup from "../../components/Forms/signup.js";
import Loading from "../../components/Loading";

function Signup() {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.title = 'JS Studio de Dança - Cadastro';
        if(localStorage.getItem('JS_KEY')) {
            window.location.href = '/dashboard';
        }
    }, []);

    return (
        <div>
            <Header />
            <div className="bg-color-primary padding-container">
                <Container className="">
                    <Row>
                        <Col xs={12}>
                            <FormSignup setIsLoading={setIsLoading} />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
            <Loading show={isLoading} />
        </div>
    );
}

export default Signup;
