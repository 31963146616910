import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { ButtonHeader } from "../Buttons";
import logo from "../../assets/img/logo_js_studio_eventos_1000x102.png";

function Header() {
  return (
    <Navbar className="fixed-top custom-navbar" bg="black" expand="lg">
      <Container className="justify-content-between">
        <Navbar.Brand href="https://jsstudiodedanca.com.br/inscricao-5-mostra-js-studio/">
          <img
            src={logo}
            className="d-inline-block align-top logo-header"
            alt="JS Studio Eventos Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto text-uppercase d-flex align-items-center">
            <Nav.Item className="text-white">
              <a href="https://jsstudiodedanca.com.br/inscricao-5-mostra-js-studio/">Home</a>
            </Nav.Item>
            <Nav.Item className="text-white">
              <a href="/cadastro">Fazer Inscrição</a>
            </Nav.Item>
            <Nav.Item className="text-white">
              <a
                href="https://api.whatsapp.com/send?phone=5541992464090"
                target="_blank"
                rel="noreferrer"
              >
                Fale Conosco
              </a>
            </Nav.Item>
            <Nav.Item className="text-white">
              <a href="/login">
                <ButtonHeader btnText="Minha Conta" />
              </a>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
