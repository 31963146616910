import React, { useState, useEffect, useCallback } from "react";
import {
    Container,
    Row,
    Col,
    Dropdown,
    Modal,
    Form,
    Alert,
    Button,
} from "react-bootstrap";
import Swal from "sweetalert2";
import Sidebar from "../../../components/Sidebar";
import {
    CardList,
    CardListHeader,
    CardListHeaderItem,
    CardListHeaderSortItem,
    CardListBody,
    CardListBodyItem,
    CardListBodyItemOptions,
} from "../../../components/CardList";
import { ButtonPrimary } from "../../../components/Buttons/index.js";
import Loading from "../../../components/Loading";
import MaterialIcon from "material-icons-react";
import api from "../../../config/api.js";
import {
    dateFormat,
    dateHourFormat,
    calculateAge,
    cpfFormat,
    isUserMinor,
    debounce,
    formatDateForInput,
} from "../../../config/utils";

function Users() {
    const [load, setLoad] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [page, setPage] = useState(1);
    const [dataInfo, setDataInfo] = useState([]);
    const [sort, setSort] = useState({ sortBy: "createdAt", order: "desc" });
    const [data, setData] = useState([]);
    const [user, setUser] = useState({});
    const [originalUser, setOriginalUser] = useState({});
    const [showModalView, setShowModalView] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [statusValue, setStatusValue] = useState("all");
    const [limitValue, setLimitValue] = useState(20);

    const getData = useCallback(
        async (page = 1) => {
            setLoad(true);
            try {
                const response = await api.get(
                    `/users?paginate=true&page=${page}&sortBy=${sort.sortBy}&order=${sort.order}&limit=${limitValue}&search=${searchValue}&status=${statusValue}`
                );
                const { docs, ...dataInfo } = response.data;
                setData(docs);
                setDataInfo(dataInfo);
            } catch (error) {
                console.log(error);
            } finally {
                setLoad(false);
            }
        },
        [sort, limitValue, searchValue, statusValue]
    );

    useEffect(() => {
        document.title = "JS Studio de Dança - Usuários";
        getData();
    }, [getData]);

    const handleInputChange = async (event) => {
        const { name, value } = event.target;

        const addressKeys = ["street", "neighborhood", "city", "uf", "number"];

        if (name === "postalCode") {
            setPostalCode(value);
            if (value.replace(/[.-]/g, "").length === 8) {
                setLoad(true);
                try {
                    const response = await api.get(`/config/cep/${value}`);
                    if (!response.data.erro) {
                        setUser((prevUser) => ({
                            ...prevUser,
                            address: {
                                ...prevUser.address,
                                street: response.data.logradouro,
                                neighborhood: response.data.bairro,
                                city: response.data.localidade,
                                uf: response.data.uf,
                                postalCode: value,
                            },
                        }));
                    }
                } catch (error) {
                    console.error("Erro ao buscar CEP:", error);
                    setUser((prevUser) => ({
                        ...prevUser,
                        address: {
                            ...prevUser.address,
                            street: "",
                            neighborhood: "",
                            city: "",
                            uf: "",
                            number: "",
                        },
                    }));
                }
                setLoad(false);
            } else if (value.length < 8) {
                setUser((prevUser) => ({
                    ...prevUser,
                    address: {
                        ...prevUser.address,
                        street: "",
                        neighborhood: "",
                        city: "",
                        uf: "",
                        number: "",
                    },
                }));
            }
        } else if (addressKeys.includes(name)) {
            setUser((prevUser) => ({
                ...prevUser,
                address: {
                    ...prevUser.address,
                    [name]: value,
                },
            }));
        } else {
            setUser((prevUser) => ({
                ...prevUser,
                [name]: value,
            }));
        }
    };

    const handleSort = (sortKey) => {
        setSort((prevSort) => {
            const isAsc =
                prevSort.sortBy === sortKey && prevSort.order === "asc";
            return {
                sortBy: sortKey,
                order: isAsc ? "desc" : "asc",
            };
        });
    };

    const startSubmitProcess = () => {
        setError(false);
        setErrorMessage("");
        setLoad(!load);
    };

    const prevPage = () => {
        if (page === 1) return;
        const pageNumber = page - 1;
        setPage(pageNumber);
        getData(pageNumber);
    };

    const nextPage = () => {
        if (page === dataInfo.pages) return;
        const pageNumber = page + 1;
        setPage(pageNumber);
        getData(pageNumber);
    };

    const getChangedFields = (original, updated) => {
        const changes = {};

        const findChanges = (originalObj, updatedObj, prefix = "") => {
            Object.keys(updatedObj).forEach((key) => {
                const fullPath = prefix ? `${prefix}.${key}` : key;

                if (
                    updatedObj[key] instanceof Object &&
                    !(updatedObj[key] instanceof Array)
                ) {
                    if (!originalObj[key]) {
                        changes[fullPath] = updatedObj[key];
                    } else {
                        findChanges(
                            originalObj[key],
                            updatedObj[key],
                            fullPath
                        );
                    }
                } else {
                    if (
                        !originalObj.hasOwnProperty(key) ||
                        originalObj[key] !== updatedObj[key]
                    ) {
                        changes[fullPath] = updatedObj[key];
                    }
                }
            });
        };

        findChanges(original, updated);
        return changes;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        startSubmitProcess();
        const changes = getChangedFields(originalUser, user);

        if (user._id) {
            await api
                .put(`/users/${user._id}`, changes)
                .then(() => {
                    setLoad(false);
                    setError(false);
                    setShowModalEdit(false);
                    getData(page);
                    Swal.fire({
                        icon: "success",
                        title: "Sucesso!",
                        text: "Usuário editado com sucesso.",
                    });
                })
                .catch((err) => {
                    setLoad(false);
                    setError(true);
                    setErrorMessage(err.response.data.error);
                });
        } else {
            await api
                .post("/users")
                .then(() => {
                    setLoad(false);
                    setError(false);
                    setShowModalEdit(false);
                    getData(page);
                    Swal.fire({
                        icon: "success",
                        title: "Usuário cadastrado!",
                        text: "Usuário criado com sucesso.",
                    });
                })
                .catch((err) => {
                    setLoad(false);
                    setError(true);
                    setErrorMessage(err.response.data.error);
                });
        }
    };

    const deleteData = async (user, remove = false) => {
        Swal.fire({
            icon: "info",
            title: "Atenção!",
            text: `Deseja ${
                remove
                    ? "remover"
                    : user.status === "active"
                    ? "inativar"
                    : "ativar"
            } o usuário? ${remove ? "Essa ação não pode ser desfeita." : ""}`,
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            dangerMode: true,
        }).then(async (res) => {
            if (res.isConfirmed) {
                setLoad(true);
                await api
                    .delete(`/users/${user._id}`, {
                        data: { remove },
                    })
                    .then(() => {
                        setLoad(false);
                        getData(page);
                        Swal.fire({
                            icon: "success",
                            title: "Sucesso!",
                            text: `Usuário administrativo ${
                                remove
                                    ? "removido"
                                    : user.status === "active"
                                    ? "inativado"
                                    : "ativado"
                            } com sucesso.`,
                        });
                    })
                    .catch((err) => {
                        setLoad(false);
                        Swal.fiew({
                            icon: "error",
                            title: "Erro!",
                            text: `Erro ao ${
                                user.status === "active" ? "inativar" : "ativar"
                            } o usuário, tente novamente mais tarde.`,
                        });
                    });
            }
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSetSearchValue = useCallback(
        debounce(setSearchValue, 20),
        []
    );

    const handleInputSearch = (event) => {
        const { name, value } = event.target;

        if (name === "search") {
            debouncedSetSearchValue(value);
        }
        if (name === "status") {
            setStatusValue(value);
        }
        if (name === "limit") {
            setLimitValue(value);
        }
    };

    const exportData = async () => {
        setLoad(true);
        await api
            .get("/export/users", {
                responseType: "blob",
            })
            .then((res) => {
                setLoad(false);
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "usuarios.csv");
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                setLoad(false);
                Swal.fire({
                    icon: "error",
                    title: "Erro!",
                    text: `Erro ao exportar dados, tente novamente mais tarde.`,
                });
            });
    };

    return (
        <>
            <Sidebar pageName="Usuários" pageUrl="/dashboard/usuarios">
                <Container fluid>
                    <Row className="m-2 justify-content-end">
                        <Col xs={12} sm={4} className="text-right">
                            <small>
                                Pesquisar por nome do usuário, telefone ou nome
                                do responsável
                            </small>
                            <input
                                type="text"
                                className="form-control input-search"
                                name="search"
                                value={searchValue}
                                onChange={handleInputSearch}
                            />
                        </Col>
                        <Col xs={12} sm={2} className="text-right">
                            <small>Filtrar por status</small>
                            <select
                                className="form-control input-search"
                                name="status"
                                value={statusValue}
                                onChange={handleInputSearch}
                            >
                                <option value="" disabled>
                                    Filtrar por status
                                </option>
                                {[
                                    {
                                        name: "Todos",
                                        value: "all",
                                    },
                                    {
                                        name: "Ativos",
                                        value: "active",
                                    },
                                    {
                                        name: "Inativos",
                                        value: "inactive",
                                    },
                                ].map((item, index) => (
                                    <option value={item.value} key={index}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col xs={12} sm={1}>
                            <small># itens</small>
                            <select
                                className="form-control input-search"
                                name="limit"
                                value={limitValue}
                                onChange={handleInputSearch}
                            >
                                <option value="" disabled>
                                    Selecionar limite
                                </option>
                                {[
                                    {
                                        name: "5",
                                        value: 5,
                                    },
                                    {
                                        name: "10",
                                        value: 10,
                                    },
                                    {
                                        name: "20",
                                        value: 20,
                                    },
                                    {
                                        name: "40",
                                        value: 40,
                                    },
                                    {
                                        name: "80",
                                        value: 80,
                                    },
                                    {
                                        name: "100",
                                        value: 100,
                                    },
                                ].map((item, index) => (
                                    <option value={item.value} key={index}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col
                            xs={12}
                            sm={1}
                            className="d-flex align-items-end justify-content-end"
                        >
                            <Button
                                className="custom-btn btn-app btt-export"
                                onClick={() => exportData()}
                            >
                                <MaterialIcon icon="file_download" />
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CardList
                                page={parseInt(dataInfo.page)}
                                data={data}
                                pages={dataInfo.totalPages}
                                callbackNext={nextPage}
                                callbackPrev={prevPage}
                            >
                                <CardListHeader className="bg-color-light-gray">
                                    <Row>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={3}
                                            onSort={handleSort}
                                            sortKey={"fullName"}
                                        >
                                            Nome
                                        </CardListHeaderSortItem>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={3}
                                            onSort={handleSort}
                                            sortKey={"email"}
                                        >
                                            E-mail
                                        </CardListHeaderSortItem>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={2}
                                            onSort={handleSort}
                                            sortKey={"createdAt"}
                                        >
                                            Data de Criação
                                        </CardListHeaderSortItem>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={2}
                                            onSort={handleSort}
                                            sortKey={"status"}
                                        >
                                            Status
                                        </CardListHeaderSortItem>
                                        <CardListHeaderItem
                                            xs={12}
                                            lg={2}
                                            className="text-center"
                                        >
                                            Gerenciar
                                        </CardListHeaderItem>
                                    </Row>
                                </CardListHeader>

                                {data.map((data, index) => (
                                    <CardListBody key={index}>
                                        <Row>
                                            <CardListBodyItem
                                                xs={12}
                                                lg={3}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Nome:"}
                                                value={data.fullName}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={3}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"E-mail:"}
                                                value={data.email}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Data de Criação:"}
                                                value={dateHourFormat(
                                                    data.createdAt
                                                )}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={2}
                                                className={`d-inline-flex align-items-center small ${
                                                    (data.status === "active" &&
                                                        "fw-bold text-success") ||
                                                    (data.status ===
                                                        "inactive" &&
                                                        "fw-bold text-danger")
                                                }`}
                                                title={"Status:"}
                                                value={
                                                    data.status === "active"
                                                        ? "Ativo"
                                                        : "Inativo"
                                                }
                                            />
                                            <CardListBodyItemOptions
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center justify-content-center"
                                            >
                                                <Dropdown.Item
                                                    className="text-success font-semi-bold text-center"
                                                    onClick={() => {
                                                        setShowModalView(true);
                                                        setUser(data);
                                                    }}
                                                >
                                                    Ver informações
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="text-primary font-semi-bold text-center"
                                                    onClick={() => {
                                                        setShowModalEdit(true);
                                                        setUser(data);
                                                        setPostalCode(
                                                            data.address
                                                                ? data.address
                                                                      .postalCode
                                                                : ""
                                                        );
                                                        setOriginalUser(data);
                                                    }}
                                                >
                                                    Editar informações
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="text-danger font-semi-bold text-center"
                                                    onClick={() =>
                                                        deleteData(data)
                                                    }
                                                >
                                                    {data.status === "active"
                                                        ? "Inativar usuário"
                                                        : "Ativar usuário"}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="text-danger font-semi-bold text-center"
                                                    onClick={() =>
                                                        deleteData(data, true)
                                                    }
                                                >
                                                    Remover usuário
                                                </Dropdown.Item>
                                            </CardListBodyItemOptions>
                                        </Row>
                                    </CardListBody>
                                ))}
                                {data.length === 0 ? (
                                    <>
                                        <CardListBody>
                                            <Col
                                                xs={12}
                                                className="d-inline-flex align-items-center text-muted small justify-content-center"
                                            >
                                                Nenhum item encontrado.
                                            </Col>
                                        </CardListBody>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </CardList>
                        </Col>
                    </Row>
                </Container>
            </Sidebar>
            <Modal
                show={showModalView}
                onHide={() => {
                    setShowModalView(false);
                    setUser({});
                }}
                size={"lg"}
            >
                <Modal.Header className="border-0 p-4 d-flex align-items-center bg-color-light-gray">
                    <Modal.Title className="d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-start justify-content-center flex-column ml-3">
                            <h5 className="mb-0 fw-bold color-dark-gray">
                                {user.fullName}
                            </h5>
                        </div>
                    </Modal.Title>
                    <div style={{ cursor: "pointer" }}>
                        <MaterialIcon
                            icon="close"
                            onClick={() => {
                                setShowModalView(false);
                                setUser({});
                            }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <h4 className="text-center mb-4 font-semi-bold">
                            DADOS PESSOAIS
                        </h4>
                        <Row className="mb-2">
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Nome</h6>
                                <p className="text-muted">{user.fullName}</p>
                            </Col>
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">E-mail</h6>
                                <p className="text-muted">{user.email}</p>
                            </Col>
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Status</h6>
                                <p
                                    className={
                                        "label " +
                                            (user.status === "active" &&
                                                "fw-bold text-success") ||
                                        (user.status === "inactive" &&
                                            "fw-bold text-danger") ||
                                        (user.status === "suspended" &&
                                            "fw-bold text-warning")
                                    }
                                >
                                    {(user.status === "active" && "Ativo") ||
                                        (user.status === "inactive" &&
                                            "Inativo") ||
                                        (user.status === "suspended" &&
                                            "Suspenso")}
                                </p>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Tipo de Usuário</h6>
                                <p className="text-muted">
                                    {(user.role === "student" && "Estudante") ||
                                        (user.role === "guardian" &&
                                            "Responsável") ||
                                        (user.role === "both" &&
                                            "Estudante e Responsável")}
                                </p>
                            </Col>
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">CPF</h6>
                                <p className="text-muted">
                                    {cpfFormat(user.cpf)}
                                </p>
                            </Col>
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Data de Nascimento</h6>
                                <p className="text-muted">
                                    {dateFormat(user.birthDate)},{" "}
                                    <strong>
                                        {calculateAge(user.birthDate)} anos
                                    </strong>
                                    .
                                </p>
                            </Col>
                        </Row>
                        <Row className="mb-2"></Row>
                        <Row className="mb-2">
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Telefone</h6>
                                <p className="text-muted">
                                    {user.phone ? user.phone : "Não informado."}
                                </p>
                            </Col>
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Celular</h6>
                                <p className="text-muted">{user.cellphone}</p>
                            </Col>
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Gênero</h6>
                                <p className="text-muted text-capitalize">
                                    {user.gender}
                                </p>
                            </Col>
                        </Row>
                        <h4 className="text-center mb-4 font-semi-bold">
                            ENDEREÇO
                        </h4>
                        <Row className="mb-2">
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Rua</h6>
                                <p className="text-muted text-capitalize">
                                    {user.address && user.address.street
                                        ? user.address.street
                                        : "Não informado."}
                                </p>
                            </Col>
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Número</h6>
                                <p className="text-muted">
                                    {user.address && user.address.number
                                        ? user.address.number
                                        : "Não informado."}
                                </p>
                            </Col>
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Bairro</h6>
                                <p className="text-muted text-capitalize">
                                    {user.address && user.address.neighborhood
                                        ? user.address.neighborhood
                                        : "Não informado."}
                                </p>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Cidade</h6>
                                <p className="text-muted text-capitalize">
                                    {user.address && user.address.city
                                        ? user.address.city
                                        : "Não informado."}
                                </p>
                            </Col>
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Estado</h6>
                                <p className="text-muted text-uppercase">
                                    {user.address && user.address.uf
                                        ? user.address.uf
                                        : "Não informado."}
                                </p>
                            </Col>
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">CEP</h6>
                                <p className="text-muted">
                                    {user.address && user.address.postalCode
                                        ? user.address.postalCode
                                        : "Não informado."}
                                </p>
                            </Col>
                        </Row>
                        {isUserMinor(user.birthDate) && (
                            <>
                                <h4 className="text-center mb-4 font-semi-bold">
                                    Dados do Responsável
                                </h4>
                                <Row className="mb-2">
                                    <Col xs={12} md={6}>
                                        <h6 className="fw-bold">
                                            Nome - Responsável
                                        </h6>
                                        <p className="text-muted">
                                            {user.guardianName
                                                ? user.guardianName
                                                : "Não informado"}
                                        </p>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <h6 className="fw-bold">
                                            Telefone - Responsável
                                        </h6>
                                        <p className="text-muted">
                                            {user.guardianPhone
                                                ? user.guardianPhone
                                                : "Não informado"}
                                        </p>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container fluid>
                        <Row className="mb-2">
                            <Col xs={12} md={6}>
                                <small className="text-muted">
                                    Criado em:{" "}
                                    <strong>
                                        {dateHourFormat(user.createdAt)}
                                    </strong>
                                    .
                                </small>
                            </Col>
                            <Col
                                xs={12}
                                md={6}
                                className="text-start text-md-end"
                            >
                                <small className="text-muted">
                                    Atualizado em:{" "}
                                    <strong>
                                        {dateHourFormat(user.updatedAt)}
                                    </strong>
                                    .
                                </small>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showModalEdit}
                onHide={() => {
                    setShowModalEdit(false);
                    setUser({});
                }}
                size={"lg"}
            >
                <Modal.Header className="border-0 p-4 d-flex align-items-center bg-color-light-gray">
                    <Modal.Title className="d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-start justify-content-center flex-column ml-3">
                            <h5 className="mb-0 fw-bold color-dark-gray">
                                {user.fullName}
                            </h5>
                        </div>
                    </Modal.Title>
                    <div style={{ cursor: "pointer" }}>
                        <MaterialIcon
                            icon="close"
                            onClick={() => {
                                setShowModalEdit(false);
                                setUser({});
                            }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Form onSubmit={handleSubmit}>
                            <h4 className="text-center mb-4 font-semi-bold text-uppercase">
                                DADOS PESSOAIS
                            </h4>
                            <Row className="mb-0 mb-md-3">
                                <Col xs={12} sm={4} className="mb-3 mb-md-2">
                                    <Form.Group>
                                        <Form.Label className="text-uppercase">
                                            Nome do Usuário
                                            {!user._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="fullName"
                                            value={user.fullName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={4} className="mb-3 mb-md-2">
                                    <Form.Group>
                                        <Form.Label className="text-uppercase">
                                            E-mail do Usuário
                                            {!user._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={user.email}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={4} className="mb-3 mb-md-2">
                                    <Form.Group>
                                        <Form.Label className="text-uppercase">
                                            CPF do Usuário
                                            {!user._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="cpf"
                                            value={cpfFormat(user.cpf)}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6} className="mb-3 mb-md-2">
                                    <Form.Group>
                                        <Form.Label className="text-uppercase">
                                            Data de Nascimento do Usuário
                                            {!user._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="birthDate"
                                            onChange={handleInputChange}
                                            value={formatDateForInput(
                                                user.birthDate
                                            )}
                                            max={
                                                new Date()
                                                    .toISOString()
                                                    .split("T")[0]
                                            }
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6} className="mb-3 mb-md-2">
                                    <Form.Group>
                                        <Form.Label className="text-uppercase">
                                            Gênero do Usuário
                                            {!user._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="gender"
                                            onChange={handleInputChange}
                                            value={user.gender}
                                            required
                                        >
                                            <option value="" disabled>
                                                Selecione seu gênero
                                            </option>
                                            {[
                                                {
                                                    name: "Masculino",
                                                    value: "masculino",
                                                },
                                                {
                                                    name: "Feminino",
                                                    value: "feminino",
                                                },
                                                {
                                                    name: "Outro",
                                                    value: "outro",
                                                },
                                            ].map((item, index) => (
                                                <option
                                                    value={item.value}
                                                    key={index}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6} className="mb-3 mb-md-2">
                                    <Form.Group>
                                        <Form.Label className="text-uppercase">
                                            Telefone do Usuário
                                            {!user._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="phone"
                                            value={user.phone}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} sm={6} className="mb-3 mb-md-2">
                                    <Form.Group>
                                        <Form.Label className="text-uppercase">
                                            Celular do Usuário
                                            {!user._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="cellphone"
                                            value={user.cellphone}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <h4 className="text-center mb-4 font-semi-bold text-uppercase">
                                Endereço
                            </h4>
                            <Row className="mb-0 mb-md-3">
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            CEP
                                            {!user._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="postalCode"
                                            onChange={handleInputChange}
                                            value={postalCode}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Rua
                                            {!user._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="street"
                                            onChange={handleInputChange}
                                            value={
                                                user.address &&
                                                user.address.street
                                            }
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Número
                                            {!user._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="number"
                                            onChange={handleInputChange}
                                            value={
                                                user.address &&
                                                user.address.number
                                            }
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Bairro
                                            {!user._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="neighborhood"
                                            onChange={handleInputChange}
                                            value={
                                                user.address &&
                                                user.address.neighborhood
                                            }
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Cidade
                                            {!user._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="city"
                                            onChange={handleInputChange}
                                            value={
                                                user.address &&
                                                user.address.city
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Estado
                                            {!user._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="uf"
                                            onChange={handleInputChange}
                                            value={
                                                user.address && user.address.uf
                                            }
                                            required
                                        >
                                            <option value="" disabled>
                                                Selecione o Estado
                                            </option>
                                            {[
                                                "AC",
                                                "AL",
                                                "AP",
                                                "AM",
                                                "BA",
                                                "CE",
                                                "DF",
                                                "ES",
                                                "GO",
                                                "MA",
                                                "MT",
                                                "MS",
                                                "MG",
                                                "PA",
                                                "PB",
                                                "PR",
                                                "PE",
                                                "PI",
                                                "RJ",
                                                "RN",
                                                "RS",
                                                "RO",
                                                "RR",
                                                "SC",
                                                "SP",
                                                "SE",
                                                "TO",
                                            ].map((item, index) => (
                                                <option
                                                    key={index}
                                                    value={item}
                                                >
                                                    {item}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {isUserMinor(user.birthDate) && (
                                <>
                                    <h4 className="text-center mb-4 font-semi-bold text-uppercase">
                                        Dados do Responsável
                                    </h4>
                                    <Row className="mb-0 mb-md-3">
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Nome Completo - Responsável
                                                    {!user._id && (
                                                        <sup className="ms-1 text-danger fw-bold">
                                                            *
                                                        </sup>
                                                    )}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="guardianName"
                                                    placeholder="Digite o Nome"
                                                    onChange={handleInputChange}
                                                    value={user.guardianName}
                                                    required={isUserMinor(
                                                        user.birthDate
                                                    )}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group className="mb-3 mb-md-2">
                                                <Form.Label className="text-uppercase">
                                                    Telefone - Responsável
                                                    {!user._id && (
                                                        <sup className="ms-1 text-danger fw-bold">
                                                            *
                                                        </sup>
                                                    )}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="guardianPhone"
                                                    placeholder="Digite o Telefone"
                                                    onChange={handleInputChange}
                                                    value={user.guardianPhone}
                                                    required={isUserMinor(
                                                        user.birthDate
                                                    )}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            <Row>
                                {error && (
                                    <div className="d-flex justify-content-center">
                                        <Alert
                                            variant={"danger"}
                                            className="mb-0 text-center small w-auto"
                                        >
                                            <strong>{errorMessage}</strong>
                                        </Alert>
                                    </div>
                                )}
                                <Col
                                    xs={12}
                                    className="d-flex justify-content-center"
                                >
                                    <ButtonPrimary
                                        btnText={
                                            user._id
                                                ? "salvar usuário"
                                                : "adicionar usuário"
                                        }
                                        type="submit"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
            <Loading show={load} />
        </>
    );
}

export default Users;
