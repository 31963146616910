import React, { useState, useEffect, useCallback } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Dropdown,
    Modal,
    Form,
    Alert,
} from "react-bootstrap";
import Swal from "sweetalert2";
import Sidebar from "../../../components/Sidebar";
import {
    CardList,
    CardListHeader,
    CardListHeaderItem,
    CardListHeaderSortItem,
    CardListBody,
    CardListBodyItem,
    CardListBodyImage,
    CardListBodyItemOptions,
} from "../../../components/CardList";
import { ButtonPrimary } from "../../../components/Buttons/index.js";
import Loading from "../../../components/Loading";
import MaterialIcon from "material-icons-react";
import api from "../../../config/api.js";
import {
    dateFormat,
    dateHourFormat,
    formatPrice,
    formatDateForInput,
    debounce,
} from "../../../config/utils";

function Events() {
    const [load, setLoad] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [page, setPage] = useState(1);
    const [dataInfo, setDataInfo] = useState([]);
    const [sort, setSort] = useState({ sortBy: "createdAt", order: "desc" });
    const [data, setData] = useState([]);
    const [eventData, setEvent] = useState({});
    const [originalEventData, setOriginalEventData] = useState({});
    const [showModalView, setShowModalView] = useState(false);
    const [showModalEditAdd, setshowModalEditAdd] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [statusValue, setStatusValue] = useState("all");
    const [limitValue, setLimitValue] = useState(20);

    const getData = useCallback(
        async (page = 1) => {
            setLoad(true);
            try {
                const response = await api.get(
                    `/events?paginate=true&page=${page}&sortBy=${sort.sortBy}&order=${sort.order}&limit=${limitValue}&search=${searchValue}&status=${statusValue}`
                );
                const { docs, ...dataInfo } = response.data;
                setData(docs);
                setDataInfo(dataInfo);
            } catch (error) {
                console.log(error);
            } finally {
                setLoad(false);
            }
        },
        [sort, limitValue, searchValue, statusValue]
    );

    useEffect(() => {
        document.title = "JS Studio de Dança - Eventos";
        getData();
    }, [getData]);

    const handleInputChange = async (event) => {
        const { name, value } = event.target;

        const priceKeys = [
            "fullPrice",
            "fullPriceLink",
            "halfEntry",
            "halfEntryLink",
            "advancePrice",
            "advancePriceLink",
            "promotionalPrice",
            "promotionalPriceLink",
        ];

        const addressKeys = ["street", "neighborhood", "city", "uf", "number"];

        if (name === "postalCode") {
            setPostalCode(value);
            if (value.replace(/[.-]/g, "").length === 8) {
                setLoad(true);
                try {
                    const response = await api.get(`/config/cep/${value}`);
                    if (!response.data.erro) {
                        setEvent((prevEvent) => ({
                            ...prevEvent,
                            address: {
                                ...prevEvent.address,
                                street: response.data.logradouro,
                                neighborhood: response.data.bairro,
                                city: response.data.localidade,
                                uf: response.data.uf,
                                postalCode: value,
                            },
                        }));
                    }
                } catch (error) {
                    console.error("Erro ao buscar CEP:", error);
                    setEvent((prevEvent) => ({
                        ...prevEvent,
                        address: {
                            ...prevEvent.address,
                            street: "",
                            neighborhood: "",
                            city: "",
                            uf: "",
                            number: "",
                        },
                    }));
                }
                setLoad(false);
            } else if (value.length < 8) {
                setEvent((prevEvent) => ({
                    ...prevEvent,
                    address: {
                        ...prevEvent.address,
                        street: "",
                        neighborhood: "",
                        city: "",
                        uf: "",
                        number: "",
                    },
                }));
            }
        } else if (addressKeys.includes(name)) {
            setEvent((prevEvent) => ({
                ...prevEvent,
                address: {
                    ...prevEvent.address,
                    [name]: value,
                },
            }));
        } else if (priceKeys.includes(name)) {
            setEvent((prevEvent) => ({
                ...prevEvent,
                prices: {
                    ...(prevEvent.prices || {}),
                    [name]: value,
                },
            }));
        } else if (name === "file") {
            setEvent((prevEvent) => ({
                ...prevEvent,
                [name]: event.target.files[0],
                fileName: event.target.files[0].name,
            }));
        } else {
            setEvent((prevEvent) => ({
                ...prevEvent,
                [name]: value,
            }));
        }
    };

    const handleSort = (sortKey) => {
        setSort((prevSort) => {
            const isAsc =
                prevSort.sortBy === sortKey && prevSort.order === "asc";
            return {
                sortBy: sortKey,
                order: isAsc ? "desc" : "asc",
            };
        });
    };

    const startSubmitProcess = () => {
        setError(false);
        setErrorMessage("");
        setLoad(!load);
    };

    const prevPage = () => {
        if (page === 1) return;
        const pageNumber = page - 1;
        setPage(pageNumber);
        getData(pageNumber);
    };

    const nextPage = () => {
        if (page === dataInfo.pages) return;
        const pageNumber = page + 1;
        setPage(pageNumber);
        getData(pageNumber);
    };

    const availablePrices = [
        {
            label: "Inteira",
            value: eventData.prices ? eventData.prices.fullPrice : null,
            link: eventData.prices ? eventData.prices.fullPriceLink : null,
        },
    ].filter((price) => price.value !== null);

    if (eventData.prices && eventData.prices.advancePrice) {
        availablePrices.push({
            label: "Adiantado",
            value: eventData.prices.advancePrice,
            link: eventData.prices ? eventData.prices.advancePriceLink : null,
        });
    }

    if (eventData.prices && eventData.prices.halfEntry) {
        availablePrices.push({
            label: "Meia-entrada",
            value: eventData.prices.halfEntry,
            link: eventData.prices ? eventData.prices.halfEntryLink : null,
        });
    }

    if (eventData.prices && eventData.prices.promotionalPrice) {
        availablePrices.push({
            label: "Promocional",
            value: eventData.prices.promotionalPrice,
            link: eventData.prices
                ? eventData.prices.promotionalPriceLink
                : null,
        });
    }

    const columnSize = 12 / availablePrices.length;

    const getChangedFields = (original, updated) => {
        const changes = {};
        for (const key in original) {
            if (original[key] !== updated[key]) {
                changes[key] = updated[key];
            }
        }
        return changes;
    };

    // const handleCreate = async (event) => {
    //     event.preventDefault();
    //     startSubmitProcess();

    //     const formData = new FormData();

    //     // Adicionando campos ao formData. Certifique-se de que os nomes dos campos correspondam aos esperados pelo back-end.
    //     formData.append("file", eventData.file, eventData.file.name);
    //     formData.append("eventName", eventData.eventName);
    //     formData.append("date", eventData.date);
    //     formData.append("duration", eventData.duration);
    //     formData.append("time", eventData.time);
    //     formData.append("ageRangeMin", eventData.ageRangeMin);
    //     formData.append("ageRangeMax", eventData.ageRangeMax);
    //     formData.append("linkMaps", eventData.linkMaps);
    //     formData.append("description", eventData.description);

    //     if (eventData.status) {
    //         formData.append("status", eventData.status);
    //     }

    //     // Adicionando objetos 'prices' e 'address' como strings JSON, se o back-end suportar
    //     if (eventData.prices) {
    //         formData.append("prices", JSON.stringify(eventData.prices));
    //     }

    //     if (eventData.address) {
    //         formData.append("address", JSON.stringify(eventData.address));
    //     }

    //     console.log(eventData);
    //     console.log(formData);

    //     // Fazendo a requisição POST
    //     await api
    //         .post("/events", formData)
    //         .then(() => {
    //             setLoad(false);
    //             setError(false);
    //             setshowModalEditAdd(false);
    //             getData(page);
    //             Swal.fire({
    //                 icon: "success",
    //                 title: "Evento cadastrado!",
    //                 text: "Informe os dados para que ele possa acessar.",
    //             });
    //         })
    //         .catch((err) => {
    //             setLoad(false);
    //             setError(true);
    //             setErrorMessage(err.response.data.error);
    //         });
    // };

    const handleSubmit = async (event) => {
        event.preventDefault();
        startSubmitProcess();
        const changes = getChangedFields(originalEventData, eventData);

        if (eventData._id) {
            await api
                .put(`/events/${eventData._id}`, changes)
                .then(() => {
                    setLoad(false);
                    setError(false);
                    setshowModalEditAdd(false);
                    getData(page);
                    Swal.fire({
                        icon: "success",
                        title: "Sucesso!",
                        text: "Evento editado com sucesso.",
                    });
                })
                .catch((err) => {
                    setLoad(false);
                    setError(true);
                    setErrorMessage(err.response.data.error);
                });
        } else {
            await api
                .post("/events", eventData)
                .then(() => {
                    setLoad(false);
                    setError(false);
                    setshowModalEditAdd(false);
                    getData(page);
                    Swal.fire({
                        icon: "success",
                        title: "Evento cadastrado!",
                        text: "Evento criado com sucesso.",
                    });
                })
                .catch((err) => {
                    setLoad(false);
                    setError(true);
                    setErrorMessage(err.response.data.error);
                });
        }
    };

    const setPrimary = async (data) => {
        Swal.fire({
            icon: "info",
            title: "Atenção!",
            text: `Deseja tornar o evento primário? Outro evento que estiver enquanto primário deixará de ser.`,
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            dangerMode: true,
        }).then(async (res) => {
            if (res.isConfirmed) {
                setLoad(true);
                await api
                    .put(`/events-primmary/${data._id}`)
                    .then(() => {
                        setLoad(false);
                        setError(false);
                        getData(page);
                        Swal.fire({
                            icon: "success",
                            title: "Sucesso!",
                            text: `Evento tornado primário com sucesso.`,
                        });
                    })
                    .catch((err) => {
                        setLoad(false);
                        setError(true);
                        setErrorMessage(err.response.data.error);
                        Swal.fiew({
                            icon: "error",
                            title: "Erro!",
                            text: `Erro ao tornar o evento primário, tente novamente mais tarde.`,
                        });
                    });
            }
        });
    };

    const deleteData = async (event, remove) => {
        Swal.fire({
            icon: "info",
            title: "Atenção!",
            text: `Deseja ${
                remove
                    ? "remover"
                    : event.status === "active"
                    ? "inativar"
                    : "ativar"
            } o evento? ${remove && "Essa ação não pode ser desfeita."}`,
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            dangerMode: true,
        }).then(async (res) => {
            if (res.isConfirmed) {
                setLoad(true);
                await api
                    .delete(`/events/${event._id}`, {
                        data: { remove },
                    })
                    .then(() => {
                        setLoad(false);
                        getData(page);
                        Swal.fire({
                            icon: "success",
                            title: "Sucesso!",
                            text: `Evento ${
                                remove
                                    ? "removido"
                                    : event.status === "active"
                                    ? "inativado"
                                    : "ativado"
                            } com sucesso.`,
                        });
                    })
                    .catch((err) => {
                        setLoad(false);
                        Swal.fiew({
                            icon: "error",
                            title: "Erro!",
                            text: `Erro ao ${
                                event.status === "active"
                                    ? "inativar"
                                    : "ativar"
                            } o usuário, tente novamente mais tarde.`,
                        });
                    });
            }
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSetSearchValue = useCallback(
        debounce(setSearchValue, 20),
        []
    );

    const handleInputSearch = (event) => {
        const { name, value } = event.target;

        if (name === "search") {
            debouncedSetSearchValue(value);
        }
        if (name === "status") {
            setStatusValue(value);
        }
        if (name === "limit") {
            setLimitValue(value);
        }
    };

    return (
        <>
            <Sidebar pageName="Eventos" pageUrl="/dashboard/eventos">
                <Container fluid>
                    <Row className="m-2 justify-content-end">
                        <Col xs={12} sm={3} className="text-right">
                            <small>Pesquisar por nome</small>
                            <input
                                type="text"
                                className="form-control input-search"
                                name="search"
                                value={searchValue}
                                onChange={handleInputSearch}
                            />
                        </Col>
                        <Col xs={12} sm={2} className="text-right">
                            <small>Filtrar por status</small>
                            <select
                                className="form-control input-search"
                                name="status"
                                value={statusValue}
                                onChange={handleInputSearch}
                            >
                                <option value="" disabled>
                                    Filtrar por status
                                </option>
                                {[
                                    {
                                        name: "Todos",
                                        value: "all",
                                    },
                                    {
                                        name: "Ativos",
                                        value: "active",
                                    },
                                    {
                                        name: "Inativos",
                                        value: "inactive",
                                    },
                                ].map((item, index) => (
                                    <option value={item.value} key={index}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col xs={12} sm={1}>
                            <small># itens</small>
                            <select
                                className="form-control input-search"
                                name="limit"
                                value={limitValue}
                                onChange={handleInputSearch}
                            >
                                <option value="" disabled>
                                    Selecionar limite
                                </option>
                                {[
                                    {
                                        name: "5",
                                        value: 5,
                                    },
                                    {
                                        name: "10",
                                        value: 10,
                                    },
                                    {
                                        name: "20",
                                        value: 20,
                                    },
                                    {
                                        name: "40",
                                        value: 40,
                                    },
                                    {
                                        name: "80",
                                        value: 80,
                                    },
                                    {
                                        name: "100",
                                        value: 100,
                                    },
                                ].map((item, index) => (
                                    <option value={item.value} key={index}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col
                            xs={12}
                            sm={2}
                            className="d-flex align-items-end justify-content-end"
                        >
                            <Button
                                className="custom-btn btn-app btt-add"
                                onClick={() => {
                                    setshowModalEditAdd(true);
                                    setPostalCode("");
                                    setEvent({});
                                }}
                            >
                                Adicionar evento
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CardList
                                page={parseInt(dataInfo.page)}
                                pages={dataInfo.pages}
                                data={data}
                                callbackNext={nextPage}
                                callbackPrev={prevPage}
                            >
                                <CardListHeader className="bg-color-light-gray">
                                    <Row>
                                        <CardListHeaderItem xs={12} lg={2}>
                                            Banner
                                        </CardListHeaderItem>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={4}
                                            onSort={handleSort}
                                            sortKey={"eventName"}
                                        >
                                            Nome
                                        </CardListHeaderSortItem>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={2}
                                            onSort={handleSort}
                                            sortKey={"date"}
                                        >
                                            Data
                                        </CardListHeaderSortItem>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={1}
                                            onSort={handleSort}
                                            sortKey={"status"}
                                        >
                                            Status
                                        </CardListHeaderSortItem>
                                        <CardListHeaderItem
                                            xs={12}
                                            lg={1}
                                            className={"text-center"}
                                        >
                                            Primário?
                                        </CardListHeaderItem>
                                        <CardListHeaderItem
                                            xs={12}
                                            lg={2}
                                            className="text-center"
                                        >
                                            Gerenciar
                                        </CardListHeaderItem>
                                    </Row>
                                </CardListHeader>

                                {data.map((data, index) => (
                                    <CardListBody key={index}>
                                        <Row>
                                            <CardListBodyImage
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center"
                                                image={data.bannerUrl}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={4}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Nome:"}
                                                value={data.eventName}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"E-mail:"}
                                                value={dateFormat(data.date)}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={1}
                                                className={`d-inline-flex align-items-center small ${
                                                    (data.status === "active" &&
                                                        "fw-bold text-success") ||
                                                    (data.status ===
                                                        "inactive" &&
                                                        "fw-bold text-danger") ||
                                                    (data.status ===
                                                        "suspended" &&
                                                        "fw-bold text-warning")
                                                }`}
                                                title={"Status:"}
                                                value={
                                                    (data.status === "active" &&
                                                        "Ativo") ||
                                                    (data.status ===
                                                        "inactive" &&
                                                        "Inativo") ||
                                                    (data.status ===
                                                        "suspended" &&
                                                        "Suspenso")
                                                }
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={1}
                                                className={`d-inline-flex justify-content-center align-items-center small ${
                                                    (data.primary &&
                                                        "fw-bold text-success") ||
                                                    (!data.primary &&
                                                        "fw-bold text-danger")
                                                }`}
                                                title={"Evento Primário:"}
                                                value={
                                                    data.primary ? "Sim" : "Não"
                                                }
                                            />
                                            <CardListBodyItemOptions
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center justify-content-center"
                                            >
                                                <Dropdown.Item
                                                    className="text-success font-semi-bold text-center"
                                                    onClick={() => {
                                                        setShowModalView(true);
                                                        setEvent(data);
                                                    }}
                                                >
                                                    Ver informações
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="text-primary font-semi-bold text-center"
                                                    onClick={() => {
                                                        setshowModalEditAdd(
                                                            true
                                                        );
                                                        setEvent(data);
                                                        setPostalCode(
                                                            data.address
                                                                ? data.address
                                                                      .postalCode
                                                                : ""
                                                        );
                                                        setOriginalEventData(
                                                            data
                                                        );
                                                    }}
                                                >
                                                    Editar informações
                                                </Dropdown.Item>
                                                {!data.primary &&
                                                    data.status ===
                                                        "active" && (
                                                        <Dropdown.Item
                                                            className="text-danger font-semi-bold text-center"
                                                            onClick={() =>
                                                                setPrimary(data)
                                                            }
                                                        >
                                                            Tornar Principal
                                                        </Dropdown.Item>
                                                    )}
                                                {!data.primary && (
                                                    <Dropdown.Item
                                                        className="text-danger font-semi-bold text-center"
                                                        onClick={() =>
                                                            deleteData(
                                                                data,
                                                                true
                                                            )
                                                        }
                                                    >
                                                        Remover evento
                                                    </Dropdown.Item>
                                                )}
                                            </CardListBodyItemOptions>
                                        </Row>
                                    </CardListBody>
                                ))}
                                {data.length === 0 ? (
                                    <>
                                        <CardListBody>
                                            <Col
                                                xs={12}
                                                className="d-inline-flex align-items-center text-muted small justify-content-center"
                                            >
                                                Nenhum item encontrado.
                                            </Col>
                                        </CardListBody>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </CardList>
                        </Col>
                    </Row>
                </Container>
            </Sidebar>
            <Modal
                show={showModalView}
                onHide={() => {
                    setShowModalView(false);
                    setEvent({});
                }}
                size={"lg"}
            >
                <Modal.Header className="border-0 p-4 d-flex align-items-center bg-color-light-gray">
                    <Modal.Title className="d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-start justify-content-center flex-column ml-3">
                            <h5 className="mb-0 fw-bold color-dark-gray">
                                {eventData.eventName}
                            </h5>
                        </div>
                    </Modal.Title>
                    <div style={{ cursor: "pointer" }}>
                        <MaterialIcon
                            icon="close"
                            onClick={() => {
                                setShowModalView(false);
                                setEvent({});
                            }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <h4 className="text-center mb-4 font-semi-bold text-uppercase">
                            Dados do Evento
                        </h4>
                        <Row className="mb-2">
                            <Col xs={12} sm={8}>
                                <h6 className="fw-bold">Nome</h6>
                                <p className="text-muted">
                                    {eventData.eventName}
                                </p>
                            </Col>
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Tipo de Evento</h6>
                                <p className="text-muted">
                                    {eventData.typeOfEvent === "show"
                                        ? "Espetáculo"
                                        : eventData.typeOfEvent === "workshop"
                                        ? "Workshop"
                                        : "Outros"}
                                </p>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Data</h6>
                                <p className="text-muted">
                                    {dateFormat(eventData.date)}
                                </p>
                            </Col>
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Horário</h6>
                                <p className="text-muted">{eventData.time}</p>
                            </Col>
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Faixa Etária</h6>
                                <p className="text-muted">
                                    de {eventData.ageRangeMin} anos, até{" "}
                                    {eventData.ageRangeMax}
                                </p>
                            </Col>
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Duração</h6>
                                <p className="text-muted">
                                    {eventData.duration}
                                </p>
                            </Col>
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Status do Evento</h6>
                                <p
                                    className={`fw-bold
                                    ${
                                        (eventData.status === "active" &&
                                            "fw-bold text-success") ||
                                        (eventData.status === "inactive" &&
                                            "fw-bold text-danger") ||
                                        (eventData.status === "suspended" &&
                                            "fw-bold text-warning")
                                    }
                                    `}
                                >
                                    {(eventData.status === "active" &&
                                        "Ativo") ||
                                        (eventData.status === "inactive" &&
                                            "Inativo") ||
                                        (eventData.status === "suspended" &&
                                            "Suspenso")}
                                </p>
                            </Col>
                        </Row>
                        <h4 className="text-center mb-4 font-semi-bold text-uppercase">
                            Preços
                        </h4>
                        <Row className="mb-2">
                            {availablePrices.map((price) => (
                                <Col
                                    xs={12}
                                    sm={6}
                                    md={columnSize}
                                    key={price.label}
                                >
                                    <h6 className="fw-bold text-center">
                                        {price.label}
                                    </h6>
                                    {price.link ? (
                                        <p className="text-muted text-center text-uppercase">
                                            {formatPrice(price.value)}{" "}
                                            <a
                                                href={price.link}
                                                className="font-medium"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                (clique aqui)
                                            </a>
                                        </p>
                                    ) : (
                                        <p className="text-muted text-capitalize">
                                            {formatPrice(price.value)}
                                        </p>
                                    )}
                                </Col>
                            ))}
                        </Row>
                        <h4 className="text-center mb-4 font-semi-bold text-uppercase">
                            Endereço
                        </h4>
                        <Row className="mb-2">
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Rua</h6>
                                <p className="text-muted text-capitalize">
                                    {eventData.address &&
                                    eventData.address.street
                                        ? eventData.address.street
                                        : "Não informado."}
                                </p>
                            </Col>
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Número</h6>
                                <p className="text-muted">
                                    {eventData.address &&
                                    eventData.address.number
                                        ? eventData.address.number
                                        : "Não informado."}
                                </p>
                            </Col>
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Bairro</h6>
                                <p className="text-muted text-capitalize">
                                    {eventData.address &&
                                    eventData.address.neighborhood
                                        ? eventData.address.neighborhood
                                        : "Não informado."}
                                </p>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Cidade</h6>
                                <p className="text-muted text-capitalize">
                                    {eventData.address && eventData.address.city
                                        ? eventData.address.city
                                        : "Não informado."}
                                </p>
                            </Col>
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">Estado</h6>
                                <p className="text-muted text-uppercase">
                                    {eventData.address && eventData.address.uf
                                        ? eventData.address.uf
                                        : "Não informado."}
                                </p>
                            </Col>
                            <Col xs={12} sm={4}>
                                <h6 className="fw-bold">CEP</h6>
                                <p className="text-muted">
                                    {eventData.address &&
                                    eventData.address.postalCode
                                        ? eventData.address.postalCode
                                        : "Não informado."}
                                </p>
                            </Col>
                        </Row>
                        <h4 className="text-center mb-4 font-semi-bold text-uppercase">
                            Descrição do Evento
                        </h4>
                        <Row className="mb-2">
                            <Col xs={12}>
                                <p className="text-muted">
                                    {eventData.description}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container fluid>
                        <Row className="mb-2">
                            <Col xs={12} md={6}>
                                <small className="text-muted">
                                    Criado em:{" "}
                                    <strong>
                                        {dateHourFormat(eventData.createdAt)}
                                    </strong>
                                    .
                                </small>
                            </Col>
                            <Col
                                xs={12}
                                md={6}
                                className="text-start text-md-end"
                            >
                                <small className="text-muted">
                                    Atualizado em:{" "}
                                    <strong>
                                        {dateHourFormat(eventData.updatedAt)}
                                    </strong>
                                    .
                                </small>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showModalEditAdd}
                onHide={() => {
                    setshowModalEditAdd(false);
                    setError(false);
                    setErrorMessage("");
                    setEvent({});
                }}
                size={"xl"}
            >
                <Modal.Header className="border-0 p-4 d-flex align-items-center bg-color-light-gray">
                    <Modal.Title className="d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-start justify-content-center flex-column ml-3">
                            <h5 className="mb-0 fw-bold color-dark-gray">
                                {eventData._id
                                    ? `Editar evento`
                                    : "Adicionar evento"}
                            </h5>
                        </div>
                    </Modal.Title>
                    <div style={{ cursor: "pointer" }}>
                        <MaterialIcon
                            icon="close"
                            onClick={() => {
                                setshowModalEditAdd(false);
                                setError(false);
                                setErrorMessage("");
                                setEvent({});
                            }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Form onSubmit={handleSubmit}>
                            <h4 className="text-center mb-4 font-semi-bold text-uppercase">
                                Dados do evento
                            </h4>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Nome do Evento
                                            {!eventData._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="eventName"
                                            value={eventData.eventName || ""}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={4}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Tipo de Evento
                                            {!eventData._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="typeOfEvent"
                                            onChange={handleInputChange}
                                            value={eventData.typeOfEvent}
                                            required
                                        >
                                            <option value="" disabled>
                                                Selecione o tipo
                                            </option>
                                            {[
                                                {
                                                    name: "Outro",
                                                    value: "Other",
                                                },
                                                {
                                                    name: "Espetáculo",
                                                    value: "show",
                                                },
                                                {
                                                    name: "Workshop",
                                                    value: "workshop",
                                                },
                                            ].map((item, index) => (
                                                <option
                                                    value={item.value}
                                                    key={index}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={4}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Status do Evento
                                            {!eventData._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="status"
                                            onChange={handleInputChange}
                                            value={eventData.status}
                                            required
                                        >
                                            <option value="" disabled>
                                                Selecione o status
                                            </option>
                                            {[
                                                {
                                                    name: "Ativo",
                                                    value: "active",
                                                },
                                                {
                                                    name: "Inativo",
                                                    value: "inactive",
                                                },
                                                {
                                                    name: "Suspenso",
                                                    value: "suspended",
                                                },
                                            ].map((item, index) => (
                                                <option
                                                    value={item.value}
                                                    key={index}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={4}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            URL do Banner
                                            {!eventData._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="bannerUrl"
                                            value={eventData.bannerUrl || ""}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={4}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Data do Evento
                                            {!eventData._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="date"
                                            placeholder="Padrão DD/MM/AAAA"
                                            onChange={handleInputChange}
                                            value={
                                                formatDateForInput(
                                                    eventData.date
                                                ) || ""
                                            }
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={4}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Duração
                                            {!eventData._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="duration"
                                            value={eventData.duration || ""}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={4}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Horário
                                            {!eventData._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="time"
                                            value={eventData.time || ""}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-0 mb-md-3">
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Faixa Etária Mínima
                                            {!eventData._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="ageRangeMin"
                                            value={eventData.ageRangeMin || ""}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Faixa Etária Máxima
                                            {!eventData._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="ageRangeMax"
                                            value={eventData.ageRangeMax || ""}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <h4 className="text-center font-semi-bold mb-4 text-uppercase">
                                Preços (padrão 0000.00)
                            </h4>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Inteira
                                            {!eventData._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="fullPrice"
                                            onChange={handleInputChange}
                                            value={
                                                eventData.prices &&
                                                eventData.prices.fullPrice
                                            }
                                            step=".01"
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Link da Inteira
                                            {!eventData._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="fullPriceLink"
                                            onChange={handleInputChange}
                                            value={
                                                eventData.prices &&
                                                eventData.prices.fullPriceLink
                                            }
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Meia-entrada
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="halfEntry"
                                            onChange={handleInputChange}
                                            value={
                                                eventData.prices &&
                                                eventData.prices.halfEntry
                                            }
                                            step=".01"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Meia-entrada Link
                                        </Form.Label>
                                        <Form.Control
                                            type="string"
                                            name="halfEntryLink"
                                            onChange={handleInputChange}
                                            value={
                                                eventData.prices &&
                                                eventData.prices.halfEntryLink
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-0 mb-md-3">
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Adiantado
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="advancePrice"
                                            onChange={handleInputChange}
                                            value={
                                                eventData.prices &&
                                                eventData.prices.advancePrice
                                            }
                                            step=".01"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Adiantado link
                                        </Form.Label>
                                        <Form.Control
                                            type="string"
                                            name="advancePriceLink"
                                            onChange={handleInputChange}
                                            value={
                                                eventData.prices &&
                                                eventData.prices
                                                    .advancePriceLink
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Promocional
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="promotionalPrice"
                                            onChange={handleInputChange}
                                            value={
                                                eventData.prices &&
                                                eventData.prices
                                                    .promotionalPrice
                                            }
                                            step=".01"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Promocional link
                                        </Form.Label>
                                        <Form.Control
                                            type="string"
                                            name="promotionalPriceLink"
                                            onChange={handleInputChange}
                                            value={
                                                eventData.prices &&
                                                eventData.prices
                                                    .promotionalPriceLink
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <h4 className="text-center mb-4 font-semi-bold text-uppercase">
                                Endereço
                            </h4>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            CEP
                                            {!eventData._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="postalCode"
                                            onChange={handleInputChange}
                                            value={postalCode}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Rua
                                            {!eventData._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="street"
                                            onChange={handleInputChange}
                                            value={
                                                eventData.address &&
                                                eventData.address.street
                                            }
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Número
                                            {!eventData._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="number"
                                            onChange={handleInputChange}
                                            value={
                                                eventData.address &&
                                                eventData.address.number
                                            }
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Bairro
                                            {!eventData._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="neighborhood"
                                            onChange={handleInputChange}
                                            value={
                                                eventData.address &&
                                                eventData.address.neighborhood
                                            }
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-0 mb-md-3">
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Cidade
                                            {!eventData._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="city"
                                            onChange={handleInputChange}
                                            value={
                                                eventData.address &&
                                                eventData.address.city
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Estado
                                            {!eventData._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="uf"
                                            onChange={handleInputChange}
                                            value={
                                                eventData.address &&
                                                eventData.address.uf
                                            }
                                            required
                                        >
                                            <option value="" disabled>
                                                Selecione o Estado
                                            </option>
                                            {[
                                                "AC",
                                                "AL",
                                                "AP",
                                                "AM",
                                                "BA",
                                                "CE",
                                                "DF",
                                                "ES",
                                                "GO",
                                                "MA",
                                                "MT",
                                                "MS",
                                                "MG",
                                                "PA",
                                                "PB",
                                                "PR",
                                                "PE",
                                                "PI",
                                                "RJ",
                                                "RN",
                                                "RS",
                                                "RO",
                                                "RR",
                                                "SC",
                                                "SP",
                                                "SE",
                                                "TO",
                                            ].map((item, index) => (
                                                <option
                                                    key={index}
                                                    value={item}
                                                >
                                                    {item}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Link do Maps do Local
                                            {!eventData._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="linkMaps"
                                            onChange={handleInputChange}
                                            value={eventData.linkMaps || ""}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <h4 className="text-center mb-4 font-semi-bold text-uppercase">
                                Descrição
                            </h4>
                            <Row className="mb-0 mb-md-3">
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Descrição
                                            {!eventData._id && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="3"
                                            name="description"
                                            onChange={handleInputChange}
                                            value={eventData.description || ""}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {error && (
                                    <div className="d-flex justify-content-center">
                                        <Alert
                                            variant={"danger"}
                                            className="mb-0 text-center small w-auto"
                                        >
                                            <strong>{errorMessage}</strong>
                                        </Alert>
                                    </div>
                                )}
                                <Col
                                    xs={12}
                                    className="d-flex justify-content-center"
                                >
                                    <ButtonPrimary
                                        btnText={
                                            eventData._id
                                                ? "salvar evento"
                                                : "adicionar evento"
                                        }
                                        type="submit"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
            <Loading show={load} />
        </>
    );
}

export default Events;
