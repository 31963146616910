import React, { useState } from "react";
import { Row, Col, Card, Form, Alert } from "react-bootstrap";
import Swal from "sweetalert2";
import { ButtonPrimary } from "../Buttons/index.js";
import api from "../../config/api.js";
import BackgroundImageSwitcher from "../BackgroundSwitcher/index.js";

function FormLogin({ setIsLoading }) {
    const [formLogin, setFormLogin] = useState(true);
    const [formPassword, setFormPassword] = useState(false);
    const [formEmail, setFormEmail] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [user, setUser] = useState({
        email: "",
        password: "",
    });

    const startLoginProcess = () => {
        setError(false);
        setErrorMessage("");
        setIsLoading(true);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        startLoginProcess();

        await api
            .post("/auth/login", user)
            .then((res) => {
                setIsLoading(false);
                localStorage.setItem("JS_KEY", res.data.token);
                if (res.data.userType === "admin") {
                    window.location.href = "/dashboard";
                } else {
                    window.location.href = "/dashboard/eventos-usuarios";
                }
            })
            .catch((err) => {
                setIsLoading(false);
                setError(true);
                setErrorMessage(
                    err.response.data.error
                        ? err.response.data.error
                        : "Estamos enfrentando problemas para realizar essa ação. Por gentileza tente novamente mais tarde."
                );
            });
    };

    const handleSubmitEmail = async (event) => {
        event.preventDefault();
        startLoginProcess();

        await api
            .post("/auth/forgot-email", user)
            .then((res) => {
                setIsLoading(false);
                Swal.fire(
                    "E-mail disparado!",
                    res.data.message,
                    "success"
                ).then(() => {
                    window.location.href = "/login";
                });
            })
            .catch((err) => {
                setIsLoading(false);
                setError(true);
                setErrorMessage(
                    err.response.data.error
                        ? err.response.data.error
                        : "Estamos enfrentando problemas para realizar essa ação. Por gentileza tente novamente mais tarde."
                );
            });
    };

    const handleSubmitPassword = async (event) => {
        event.preventDefault();
        startLoginProcess();

        await api
            .post("/auth/forgot-password", user)
            .then((res) => {
                setIsLoading(false);
                Swal.fire(
                    "E-mail disparado!",
                    res.data.message,
                    "success"
                ).then(() => {
                    window.location.href = "/login";
                });
            })
            .catch((err) => {
                setIsLoading(false);
                setError(true);
                setErrorMessage(
                    err.response.data.error
                        ? err.response.data.error
                        : "Estamos enfrentando problemas para realizar essa ação. Por gentileza tente novamente mais tarde."
                );
            });
    };

    return (
        <Card className="rounded-0 card-form">
            <Row className="m-0">
                {formLogin && (
                    <Col xs={12} md={6} className="padding-card">
                        <h2 className="text-uppercase font-semi-bold mb-0">
                            Já possui cadastro?
                        </h2>
                        <p className="font-light color-gray mb-4">
                            Digite suas informações e acesse sua conta.
                        </p>
                        <Form onSubmit={handleSubmit}>
                            <Row className="mb-4">
                                <Col xs={12}>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-uppercase">
                                            E-mail
                                            <sup className="ms-1 text-danger fw-bold">
                                                *
                                            </sup>
                                        </Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder="Digite seu E-MAIL"
                                            onChange={handleInputChange}
                                            value={user.email}
                                            required
                                        />
                                        <div className="d-flex justify-content-end">
                                            <Form.Text
                                                className="color-light-gray small-info"
                                                onClick={() => {
                                                    setFormLogin(false);
                                                    setFormEmail(true);
                                                }}
                                            >
                                                Esqueceu o seu e-mail?
                                            </Form.Text>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label className="text-uppercase">
                                            Senha
                                            <sup className="ms-1 text-danger fw-bold">
                                                *
                                            </sup>
                                        </Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            placeholder="Digite sua Senha"
                                            onChange={handleInputChange}
                                            value={user.password}
                                            required
                                        />
                                        <div className="d-flex justify-content-end">
                                            <Form.Text
                                                className="color-light-gray small-info"
                                                onClick={() => {
                                                    setFormLogin(false);
                                                    setFormPassword(true);
                                                }}
                                            >
                                                Esqueceu a seu senha?
                                            </Form.Text>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    xs={12}
                                    className="d-flex flex-column flex-md-row justify-content-center justify-content-md-end"
                                >
                                    <ButtonPrimary
                                        type="submit"
                                        btnText="ENTRAR NA MINHA CONTA"
                                        className={'w-100'}
                                    />
                                </Col>
                                {error && (
                                    <Col
                                        xs={12}
                                        className="mt-3 mb-0 text-center small"
                                    >
                                        <Alert variant={"danger"}>
                                            <strong>{errorMessage}</strong>
                                        </Alert>
                                    </Col>
                                )}
                            </Row>
                        </Form>
                    </Col>
                )}
                {formEmail && (
                    <Col xs={12} md={6} className="padding-card-second">
                        <Row className="mb-4">
                            <Col xs={12} className="d-flex justify-content-end">
                                <h6
                                    className="text-uppercase mb-0"
                                    onClick={() => {
                                        setFormLogin(true);
                                        setFormEmail(false);
                                    }}
                                >
                                    Voltar
                                </h6>
                            </Col>
                        </Row>
                        <h2 className="font-bold">
                            RECUPERAR O E-MAIL CADASTRADO
                        </h2>
                        <p className="font-light color-gray">
                            Informe o CPF para que possamos verificar em nosso
                            sistema o e-mail associado a ele.
                        </p>
                        <Form className="mb-5" onSubmit={handleSubmitEmail}>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-uppercase">
                                            CPF
                                            <sup className="ms-1 text-danger fw-bold">
                                                *
                                            </sup>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="cpf"
                                            placeholder="Digite o seu CPF"
                                            onChange={handleInputChange}
                                            value={user.cpf}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    xs={12}
                                    className="d-flex justify-content-center justify-content-md-end"
                                >
                                    <ButtonPrimary
                                        type="submit"
                                        btnText="RECUPERAR O MEU E-MAIL"
                                    />
                                </Col>
                                {error && (
                                    <Alert
                                        variant={"danger"}
                                        className="mt-3 mb-0 text-center small"
                                    >
                                        <strong>{errorMessage}</strong>
                                    </Alert>
                                )}
                            </Row>
                        </Form>
                        <p className="help-text pe-0 pe-md-5">
                            <strong className="me-1 text-danger fw-bold">
                                *
                            </strong>
                            Se você tem certeza de que realizou o cadastro, mas
                            o sistema não encontrou os seus dados, é possível
                            que seu CPF tenha sido cadastrado incorretamente no
                            momento do registro. Para resolver esse problema,
                            entre em contato com nossa equipe. {" "}
                            <a
                                href="https://api.whatsapp.com/send?phone=5541992464090"
                                title=""
                                target="_blank"
                                rel="noreferrer"
                            >
                                Falar com equipe!
                            </a>
                        </p>
                    </Col>
                )}
                {formPassword && (
                    <Col xs={12} md={6} className="padding-card-second">
                        <Row className="mb-5">
                            <Col xs={12} className="d-flex justify-content-end">
                                <h6
                                    className="text-uppercase mb-0"
                                    onClick={() => {
                                        setFormLogin(true);
                                        setFormPassword(false);
                                    }}
                                >
                                    Voltar
                                </h6>
                            </Col>
                        </Row>
                        <h2 className="font-bold">REDEFINIR MINHA SENHA</h2>
                        <p className="font-light color-gray">
                            Digite o e-mail cadastrado para receber um e-mail
                            com as instruções para redefinir sua senha.
                        </p>
                        <Form className="mb-5" onSubmit={handleSubmitPassword}>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-uppercase">
                                            E-MAIL
                                            <sup className="ms-1 text-danger fw-bold">
                                                *
                                            </sup>
                                        </Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder="Digite o seu E-MAIL"
                                            onChange={handleInputChange}
                                            value={user.email}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    xs={12}
                                    className="d-flex justify-content-center justify-content-md-end"
                                >
                                    <ButtonPrimary
                                        type="submit"
                                        btnText="REDEFINIR MINHA SENHA"
                                    />
                                </Col>
                                {error && (
                                    <Alert
                                        variant={"danger"}
                                        className="mt-3 mb-0 text-center small"
                                    >
                                        <strong>{errorMessage}</strong>
                                    </Alert>
                                )}
                            </Row>
                        </Form>
                        <p className="help-text pe-0 pe-md-5">
                            <strong className="me-1 text-danger fw-bold">
                                *
                            </strong>
                            Lembre-se de verificar a caixa de spam do seu
                            e-mail, pois às vezes nossa mensagem pode parar lá.
                            Caso não se lembre do e-mail usado no cadastro, {" "}
                            <a
                                href="https://api.whatsapp.com/send?phone=5541992464090"
                                title=""
                                target="_blank"
                                rel="noreferrer"
                            >
                                clique aqui para recuperá-lo.
                            </a>
                        </p>
                    </Col>
                )}
                <Col xs={12} md={6} className="d-none d-md-block p-0">
                    <BackgroundImageSwitcher />
                </Col>
            </Row>
        </Card>
    );
}

export default FormLogin;
