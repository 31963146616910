import moment from "moment";

export const dateFormat = (date) => {
    return moment.utc(date).format("DD/MM/YYYY");
};

export const dateHourFormat = (date) => {
    return moment(date).format("DD/MM/YYYY HH:mm");
};

export const calculateAge = (date) => {
    const currentDate = moment();
    const birthDate = moment.utc(date);

    return currentDate.diff(birthDate, "years");
};

export const isUserMinor = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const m = today.getMonth() - birth.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
        age--;
    }
    return age < 18;
};

export const cpfFormat = (cpf) => {
    if (!cpf || cpf.length !== 11 || !/^\d{11}$/.test(cpf)) {
        return 'CPF inválido'; 
    }
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

export const formatPrice = (price) => {
    if (typeof price !== 'number') return "Não informado.";
    return `R$ ${price.toFixed(2).replace('.', ',')}`;
};

export const formatDateForInput = (isoDateString) => {
    if (!isoDateString) return "";
    return isoDateString.split('T')[0];
};

export const getTodayDate = function() {
    const now = new Date();
    const localOffset = now.getTimezoneOffset() * 60000;
    const localTime = new Date(now.getTime() - localOffset);
    return localTime.toISOString().split('T')[0];
}

export const formatPostalCode = (postalCode) => {
    let onlyNumbers = postalCode.replace(/\D/g, '');
    while(onlyNumbers.length < 8) {
        onlyNumbers = '0' + onlyNumbers;
    }
    
    return onlyNumbers;
};

export function formatBrazilianAddress(address) {
    if (!address) {
        return '';
    }

    const { street, number, neighborhood, city, uf, postalCode } = address;

    return `${street}, ${number} - ${neighborhood}, ${city}-${uf} - ${postalCode}`;
}

export function debounce(func, delay) {
    let debounceTimer;
    return function(...args) {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(this, args), delay);
    };
}