import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTiktok,
  faFacebookF,
  faYoutube,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { ButtonFooter } from "../Buttons";
import logo from "../../assets/img/logo_jsstudio_de_danca.png";

function Footer() {
  return (
    <footer className="bg-black text-white py-4">
      <Container>
        <Row className="align-items-center mb-4">
          <Col
            xs={12}
            md={2}
            className="mb-4 mb-md-0 text-center text-md-start"
          >
            <a href="https://jsstudiodedanca.com.br">
              <Image
                src={logo}
                className="logo-footer mx-auto d-block"
                alt="JS Studio de Dança"
                fluid
              />
            </a>
          </Col>
          <Col xs={12} md={10} className="text-center text-md-start">
            <Row className="ms-0 ms-md-4 me-0">
              <Col xs={12} md={4} className="mb-4 mb-md-0">
                <h4 className="text-white text-uppercase font-bold-italic">
                  Atendimento
                </h4>
                <p>
                  <a
                    href="https://api.whatsapp.com/send?phone=5541992464090"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faWhatsapp} size="lg" />{" "}
                    <span className="ms-1">+55 (41) 99246-4090</span>
                  </a>
                </p>
                <p>
                  <a href="tel:5541992464090" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faPhone} size="lg" />{" "}
                    <span className="ms-1">+55 (41) 99246-4090</span>
                  </a>
                </p>
                <p>
                  <a href="mailto:contato@jstudiodedanca.com.br">
                    <FontAwesomeIcon icon={faEnvelope} size="lg" />{" "}
                    <span className="ms-1">contato@jstudiodedanca.com.br</span>
                  </a>
                </p>
              </Col>
              <Col xs={12} md={4} className="mb-4 mb-md-0">
                <h4 className="text-white text-uppercase font-bold-italic">
                  Contato
                </h4>
                <p>Seg a Sex: 14:00 às 22:00</p>
                <p>Sábado: 09:00 às 17:30</p>
                <p>Feriados: Consultar Horários</p>
              </Col>
              <Col xs={12} md={4} className="mb-4 mb-md-0">
                <h4 className="text-white text-uppercase font-bold-italic">
                  Endereço
                </h4>
                <p>
                  Rua Augusto Stresser, 1745, Hugo Lange Curitiba, Paraná,
                  Brasil | CEP: 80.030-340
                </p>
                <p>
                  <a
                    href="https://www.google.com.br/maps/place/JS+Studio+de+Dan%C3%A7a/@-25.4175124,-49.2467646,17z/data=!3m1!4b1!4m5!3m4!1s0x94dce5e3361d4147:0xe33dfbddf1319b3!8m2!3d-25.4175173!4d-49.2445759"
                    target="_blank"
                    rel="noreferrer"
                    className="btn-chegar"
                  >
                    Como Chegar
                  </a>
                </p>
              </Col>
              <Col
                xs={12}
                className="mt-0 mt-md-4 d-flex flex-column flex-md-row justify-content-md-end px-4 px-md-0"
              >
                <a
                  href="https://jsstudiodedanca.com.br/inscricao-5-mostra-js-studio/politica-privacidade.html"
                  target="_blank"
                  rel="noreferrer"
                  className="text-uppercase mb-3 mb-md-0 me-md-3 align-self-center"
                >
                  <p>Política de Privacidade</p>
                </a>
                <a href="tel:5541992464090" target="_blank" rel="noreferrer">
                  <ButtonFooter btnText={"ENVIAR MENSAGEM"} />
                </a>
                <div className="d-flex justify-content-center align-items-center ms-0 ms-md-3 mt-3 mt-md-0">
                  <a
                    href="https://www.instagram.com/jsstudiodedanca/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      className="mx-2 social-media-icon"
                      icon={faInstagram}
                      size="xl"
                    />
                  </a>
                  <a
                    href="https://www.tiktok.com/@jsstudiodedanca"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      className="mx-2 social-media-icon"
                      icon={faTiktok}
                      size="xl"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/JSStudiodeDanca/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      className="mx-2 social-media-icon"
                      icon={faFacebookF}
                      size="xl"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UC9pcKChidSY2reUlzG38f7A?view_as=subscriber"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      className="mx-2 social-media-icon"
                      icon={faYoutube}
                      size="xl"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/jsstudiodedanca"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      className="ms-2 social-media-icon"
                      icon={faLinkedin}
                      size="xl"
                    />
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mx-5 mx-md-0 copyright">
            <p className="color-gray">
              © {new Date().getFullYear()} - JS Studio de Dança. Todos os
              Direitos Reservados. Criado por{" "}
              <a
                className="color-gray font-semi-bold text-decoration-none"
                href="https://starchild-tech.github.io/"
                target="_blank"
                rel="noreferrer"
              >
                starchild
              </a>
              .
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
