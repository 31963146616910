import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Alert } from "react-bootstrap";
import Swal from "sweetalert2";
import { ButtonPrimary } from "../Buttons/index.js";
import api from "../../config/api.js";
import BackgroundImageSwitcher from "../BackgroundSwitcher/index.js";
import { useParams } from "react-router-dom";

function FormPasswordReset({ setIsLoading }) {
    const [error, setError] = useState(false);
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [tokenValid, setTokenValid] = useState(false);

    const { hash } = useParams();

    useEffect(() => {
        const validateToken = async () => {
            try {
                const response = await api.get(`/auth/forgot-password/${hash}`);
                if (response.data.message === "Token válido.") {
                    setTokenValid(true);
                }
            } catch (err) {
                setTokenValid(false);
                setError(true);
                setErrorMessage(err.response.data.error);
            }
        };

        validateToken();
    }, [hash]);

    const [user, setUser] = useState({
        password: "",
        confirmPassword: "",
        token: hash,
    });

    const startPasswordResetProcess = () => {
        setError(false);
        setErrorMessage("");
        setIsLoading(true);
    };

    const handleInputChange = async (event) => {
        const { name, value } = event.target;

        setUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));

        if (name === "confirmPassword") {
            setPasswordMismatch(user.password !== value);
        } else if (name === "password" && user.confirmPassword) {
            setPasswordMismatch(value !== user.confirmPassword);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        startPasswordResetProcess();

        await api
            .post("/auth/password-reset", user)
            .then((res) => {
                setIsLoading(false);
                Swal.fire({
                    icon: "success",
                    title: "Senha alterada!",
                    text: "Sua senha foi alterada com sucesso. Pode iniciar o processo de login na próxima página.",
                }).then(() => {
                    window.location.href = "/login";
                });
            })
            .catch((err) => {
                setIsLoading(false);
                setError(true);
                setErrorMessage(err.response.data.error);
            });
    };

    return (
        <Card className="rounded-0 card-form" style={{ minHeight: "50vh" }}>
            <Row className="m-0 h-100">
                <Col xs={12} md={6} className="padding-card">
                    {tokenValid ? (
                        <>
                            <h2 className="text-uppercase font-semi-bold mb-0">
                                Alteração de Senha
                            </h2>
                            <p className="font-light color-gray mb-4">
                                Digite uma nova para verificação.
                            </p>
                            <Form onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Col xs={12} className="mb-3">
                                        <Form.Group className="mb-2">
                                            <Form.Label className="text-uppercase">
                                                Senha
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            </Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="password"
                                                placeholder="Digite sua nova senha"
                                                onChange={handleInputChange}
                                                value={user.password}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label className="text-uppercase">
                                                Confirmação de Senha
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            </Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="confirmPassword"
                                                placeholder="Digite a confirmação da senha"
                                                onChange={handleInputChange}
                                                value={user.confirmPassword}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {passwordMismatch && (
                                    <Alert
                                        variant={"danger"}
                                        className="mt-4 text-center"
                                    >
                                        As senhas não coincidem.
                                    </Alert>
                                )}
                                <Row>
                                    <Col
                                        xs={12}
                                        className="d-flex justify-content-center justify-content-md-end"
                                    >
                                        <ButtonPrimary type="submit" btnText="RESETAR MINHA SENHA" />
                                    </Col>
                                    {error && (
                                        <Alert
                                            variant={"danger"}
                                            className="mt-3 mb-0 text-center small"
                                        >
                                            <strong>{errorMessage}</strong>
                                        </Alert>
                                    )}
                                </Row>
                            </Form>
                        </>
                    ) : (
                        <>
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <h2 className="text-uppercase font-semi-bold">
                                    {errorMessage}
                                </h2>
                            </div>
                        </>
                    )}
                </Col>
                <Col
                    xs={12}
                    md={6}
                    className="d-none d-md-block p-0"
                    style={{ minHeight: "50vh" }}
                >
                    <BackgroundImageSwitcher />
                </Col>
            </Row>
        </Card>
    );
}

export default FormPasswordReset;
