import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal, Form, Alert } from "react-bootstrap";
import MaterialIcon from "material-icons-react";
import Swal from "sweetalert2";
import {
    CardList,
    CardListHeader,
    CardListHeaderItem,
    CardListBody,
    CardListBodyItem,
    CardListBodyImage,
    CardListBodyButton,
} from "../../../../components/CardList";
import Sidebar from "../../../../components/Sidebar";
import Loading from "../../../../components/Loading";
import api from "../../../../config/api.js";
import { ButtonCallToACtion } from "../../../../components/Buttons";
import {
    dateFormat,
    formatBrazilianAddress,
    formatPrice,
} from "../../../../config/utils";

function EventsUsers() {
    const [load, setLoad] = useState(false);
    const [error, setError] = useState(false);
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [order, setOrder] = useState([]);
    const [dataInfo, setDataInfo] = useState([]);
    const [data, setData] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [page, setPage] = useState(1);
    const [showModalView, setShowModalView] = useState(false);

    useEffect(() => {
        document.title = "JS Studio de Dança - Eventos";

        getData();
    }, []);

    const startSubmitProcess = () => {
        setError(false);
        setErrorMessage("");
        setLoad(!load);
    };

    const handleInputChange = async (event) => {
        const { name, value } = event.target;
        setOrder((prevOrder) => ({
            ...prevOrder,
            classes: {
                ...(prevOrder.classes || {}),
                [name]: value,
            },
        }));
    };

    const prevPage = () => {
        if (page === 1) return;
        const pageNumber = page - 1;
        setPage(pageNumber);
        getData(pageNumber);
    };

    const nextPage = () => {
        if (page === dataInfo.pages) return;
        const pageNumber = page + 1;
        setPage(pageNumber);
        getData(pageNumber);
    };

    const getData = async (page = 1) => {
        setLoad(true);
        await api
            .get(`/events-user?page=${page}`)
            .then((response) => {
                const { docs, ...dataInfo } = response.data;
                setData(docs);
                setDataInfo(dataInfo);
                setLoad(false);
            })
            .catch((error) => {
                setLoad(false);
                console.log(error);
            });
    };

    const availablePrices = [
        {
            label: "Inteira",
            value: eventData.prices ? eventData.prices.fullPrice : null,
            link: eventData.prices ? eventData.prices.fullPriceLink : null,
        },
    ].filter((price) => price.value !== null);

    if (eventData.prices && eventData.prices.advancePrice) {
        availablePrices.push({
            label: "Adiantado",
            value: eventData.prices.advancePrice,
            link: eventData.prices ? eventData.prices.advancePriceLink : null,
        });
    }

    if (eventData.prices && eventData.prices.halfEntry) {
        availablePrices.push({
            label: "Meia-entrada",
            value: eventData.prices.halfEntry,
            link: eventData.prices ? eventData.prices.halfEntryLink : null,
        });
    }

    if (eventData.prices && eventData.prices.promotionalPrice) {
        availablePrices.push({
            label: "Promocional",
            value: eventData.prices.promotionalPrice,
            link: eventData.prices
                ? eventData.prices.promotionalPriceLink
                : null,
        });
    }

    const columnSize = 12 / availablePrices.length;

    const submitOrder = async (event) => {
        event.preventDefault();

        if (!hasAcceptedTerms) {
            setError(true);
            setErrorMessage(
                "Você deve aceitar os termos e condições para se registrar."
            );
            return;
        }

        startSubmitProcess();

        await api
            .post("/orders", order)
            .then(() => {
                setLoad(false);
                setError(false);
                setShowModalView(false);
                getData(page);

                let counter = 3;
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        confirmButton: "btn btn-success",
                        cancelButton: "btn btn-danger",
                    },
                    buttonsStyling: false,
                    allowOutsideClick: false,
                });

                const timerInterval = setInterval(() => {
                    counter--;
                    if (counter === 0) {
                        clearInterval(timerInterval);
                        window.open(order.paymentLink, "_blank");
                    }
                }, 1000);

                swalWithBootstrapButtons
                    .fire({
                        title: "Redirecionando para a página de pagamento em...",
                        html: `Você será redirecionado em <b></b> segundos.`,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                            const b =
                                Swal.getHtmlContainer()?.querySelector("b");
                            timerInterval &&
                                b &&
                                (b.textContent = String(counter));
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    })
                    .then((result) => {
                        if (result.dismiss === Swal.DismissReason.timer) {
                            
                        }
                    });
            })
            .catch((err) => {
                setLoad(false);
                setError(true);
                setErrorMessage(err.response.data.error);
            });
    };

    return (
        <>
            <Sidebar pageName="Eventos" pageUrl="/dashboard/eventos-usuarios">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <CardList
                                page={parseInt(dataInfo.page)}
                                pages={dataInfo.pages}
                                callbackNext={nextPage}
                                callbackPrev={prevPage}
                                countItems={dataInfo.total}
                            >
                                <CardListHeader className="bg-color-light-gray">
                                    <Row>
                                        <CardListHeaderItem xs={12} lg={2}>
                                            Banner
                                        </CardListHeaderItem>
                                        <CardListHeaderItem xs={12} lg={4}>
                                            Nome
                                        </CardListHeaderItem>
                                        <CardListHeaderItem xs={12} lg={2}>
                                            Data e Horário
                                        </CardListHeaderItem>
                                        <CardListHeaderItem xs={12} lg={2}>
                                            Faixa Etária
                                        </CardListHeaderItem>
                                        <CardListHeaderItem
                                            xs={12}
                                            lg={2}
                                            className="text-center"
                                        >
                                            Ação
                                        </CardListHeaderItem>
                                    </Row>
                                </CardListHeader>

                                {data.map((data, index) => (
                                    <CardListBody key={index}>
                                        <Row>
                                            <CardListBodyImage
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center"
                                                image={data.bannerUrl}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={4}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Nome do Evento:"}
                                                value={data.eventName}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Data e Horário:"}
                                                value={`${dateFormat(
                                                    data.date
                                                )}, às ${data.time}`}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Faixa Etária:"}
                                                value={`de ${data.ageRangeMin} até ${data.ageRangeMax} anos`}
                                            />
                                            <CardListBodyButton
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center justify-content-center text-muted small"
                                                title={"INSCREVER-SE"}
                                                callToAction={() => {
                                                    setShowModalView(true);
                                                    setEventData(data);
                                                }}
                                            />
                                        </Row>
                                    </CardListBody>
                                ))}
                                {data.length === 0 ? (
                                    <>
                                        <CardListBody>
                                            <Col
                                                xs={12}
                                                className="d-inline-flex align-items-center text-muted small justify-content-center"
                                            >
                                                Nenhum item encontrado.
                                            </Col>
                                        </CardListBody>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </CardList>
                        </Col>
                    </Row>
                </Container>
            </Sidebar>
            <Modal
                show={showModalView}
                onHide={() => {
                    setShowModalView(false);
                    setError(false);
                    setErrorMessage("");
                    setEventData({});
                    setHasAcceptedTerms(false);
                }}
                size={"lg"}
            >
                <Modal.Header className="border-0 p-4 d-flex align-items-center bg-color-light-gray">
                    <Modal.Title className="d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-start justify-content-center flex-column ml-3">
                            <h5 className="mb-0 fw-bold color-dark-gray">
                                {eventData.eventName}
                            </h5>
                        </div>
                    </Modal.Title>
                    <div style={{ cursor: "pointer" }}>
                        <MaterialIcon
                            icon="close"
                            onClick={() => {
                                setShowModalView(false);
                                setError(false);
                                setErrorMessage("");
                                setEventData({});
                                setHasAcceptedTerms(false);
                            }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <h4 className="text-center mb-4 font-semi-bold text-uppercase">
                            Dados do Evento
                        </h4>
                        <Row className="mb-2">
                            <Col xs={12} sm={6}>
                                <h6 className="fw-bold">Nome</h6>
                                <p className="text-muted">
                                    {eventData.eventName}
                                </p>
                            </Col>
                            <Col xs={12} sm={6}>
                                <h6 className="fw-bold">Descrição</h6>
                                <p className="text-muted">
                                    {eventData.description}
                                </p>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs={12} sm={3}>
                                <h6 className="fw-bold">Data</h6>
                                <p className="text-muted">
                                    {dateFormat(eventData.date)}
                                </p>
                            </Col>
                            <Col xs={12} sm={3}>
                                <h6 className="fw-bold">Horário</h6>
                                <p className="text-muted">{eventData.time}</p>
                            </Col>
                            <Col xs={12} sm={3}>
                                <h6 className="fw-bold">Faixa Etária</h6>
                                <p className="text-muted">
                                    de {eventData.ageRangeMin} anos, até{" "}
                                    {eventData.ageRangeMax}
                                </p>
                            </Col>
                            <Col xs={12} sm={3}>
                                <h6 className="fw-bold">Duração</h6>
                                <p className="text-muted">
                                    {eventData.duration}
                                </p>
                            </Col>
                        </Row>
                        <h4 className="text-center mb-2 font-semi-bold text-uppercase">
                            Endereço
                        </h4>
                        <Row>
                            <Col xs={12} sm={12}>
                                <a
                                    href={eventData.linkMaps}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <h6 className="text-center font-semi-bold">
                                        {formatBrazilianAddress(
                                            eventData.address
                                        )}
                                    </h6>
                                </a>
                            </Col>
                        </Row>
                        <h4 className="text-center mt-4 mb-2 font-semi-bold text-uppercase">
                            Preços
                        </h4>
                        <Row className="mb-2">
                            {availablePrices.map((price) => (
                                <Col
                                    xs={12}
                                    sm={6}
                                    md={columnSize}
                                    key={price.label}
                                >
                                    <h6 className="fw-bold text-center">
                                        {price.label}
                                    </h6>
                                    {price.link ? (
                                        <p className="text-muted text-center text-uppercase">
                                            {formatPrice(price.value)}
                                        </p>
                                    ) : (
                                        <p className="text-muted text-capitalize">
                                            {formatPrice(price.value)}
                                        </p>
                                    )}
                                </Col>
                            ))}
                        </Row>
                        <Row className="mb-2">
                            <Col xs={12}>
                                <Form onSubmit={submitOrder}>
                                    {eventData.typeOfEvent === "show" && (
                                        <div className="mb-4">
                                            <h3 className="mb-4 font-semi-bold text-uppercase">
                                                Quais turmas <br />
                                                você dançará?
                                            </h3>
                                            <Row>
                                                <Col
                                                    xs={12}
                                                    md={1}
                                                    className="d-flex align-items-end justify-content-start ps-3"
                                                >
                                                    <h1 className="font-extra-bold">
                                                        1.
                                                        <sup className="ms-1 text-danger fw-bold">
                                                            *
                                                        </sup>
                                                    </h1>
                                                </Col>
                                                <Col xs={12} md={5}>
                                                    <Form.Group className="mb-3 mb-md-2">
                                                        <Form.Label className="text-uppercase">
                                                            Modalidade
                                                            <sup className="ms-1 text-danger fw-bold">
                                                                *
                                                            </sup>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="firstClassName"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Form.Group className="mb-3 mb-md-2">
                                                        <Form.Label className="text-uppercase">
                                                            Dias
                                                            <sup className="ms-1 text-danger fw-bold">
                                                                *
                                                            </sup>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="firstClassDates"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Form.Group className="mb-3 mb-md-2">
                                                        <Form.Label className="text-uppercase">
                                                            Horário
                                                            <sup className="ms-1 text-danger fw-bold">
                                                                *
                                                            </sup>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="firstClassHour"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    xs={12}
                                                    md={1}
                                                    className="d-flex align-items-end justify-content-start ps-3"
                                                >
                                                    <h1 className="font-extra-bold">
                                                        2.
                                                    </h1>
                                                </Col>
                                                <Col xs={12} md={5}>
                                                    <Form.Group className="mb-3 mb-md-2">
                                                        <Form.Label className="text-uppercase">
                                                            Modalidade
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="secondClassName"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Form.Group className="mb-3 mb-md-2">
                                                        <Form.Label className="text-uppercase">
                                                            Dias
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="secondClassDates"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Form.Group className="mb-3 mb-md-2">
                                                        <Form.Label className="text-uppercase">
                                                            Horário
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="secondClassHour"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    xs={12}
                                                    md={1}
                                                    className="d-flex align-items-end justify-content-start ps-3"
                                                >
                                                    <h1 className="font-extra-bold">
                                                        3.
                                                    </h1>
                                                </Col>
                                                <Col xs={12} md={5}>
                                                    <Form.Group className="mb-3 mb-md-2">
                                                        <Form.Label className="text-uppercase">
                                                            Modalidade
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="thirdClassName"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Form.Group className="mb-3 mb-md-2">
                                                        <Form.Label className="text-uppercase">
                                                            Dias
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="thirdClassDates"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Form.Group className="mb-3 mb-md-2">
                                                        <Form.Label className="text-uppercase">
                                                            Horário
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="thirdClassHour"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    xs={12}
                                                    md={1}
                                                    className="d-flex align-items-end justify-content-start ps-3"
                                                >
                                                    <h1 className="font-extra-bold">
                                                        4.
                                                    </h1>
                                                </Col>
                                                <Col xs={12} md={5}>
                                                    <Form.Group className="mb-3 mb-md-2">
                                                        <Form.Label className="text-uppercase">
                                                            Modalidade
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="fourthClassName"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Form.Group className="mb-3 mb-md-2">
                                                        <Form.Label className="text-uppercase">
                                                            Dias
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="fourthClassDates"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Form.Group className="mb-3 mb-md-2">
                                                        <Form.Label className="text-uppercase">
                                                            Horário
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="fourthClassHour"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <small className="color-light-gray">
                                                    Os campos marcados com um
                                                    <strong className="ms-1 text-danger fw-bold">
                                                        *
                                                    </strong>{" "}
                                                    são de preenchimento
                                                    obrigatório. Não se esqueça
                                                    de preenchê-los.
                                                    <br />
                                                    Certifique-se de revisar e
                                                    confirmar as informações
                                                    inseridas no formulário a
                                                    cima, garantindo que estejam
                                                    corretas e verdadeiras.
                                                </small>
                                            </Row>
                                        </div>
                                    )}
                                    <Row className="mb-0 mb-md-3">
                                        <Col
                                            xs={12}
                                            className="d-flex justify-content-start mt-4"
                                        >
                                            <Form.Group className="mb-2">
                                                <Form.Check
                                                    type="checkbox"
                                                    label={
                                                        <span>
                                                            Ao marcar esta
                                                            opção, você concorda
                                                            com os{" "}
                                                            <a
                                                                href="https://www.jsstudiodedanca.com.br/inscricao-5-mostra-js-studio/js_studio_eventos_termos_de_uso_e_politica_de_privacidade.pdf"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <strong>
                                                                    Termos &
                                                                    Condições
                                                                </strong>
                                                            </a>{" "}
                                                            do evento.
                                                        </span>
                                                    }
                                                    onChange={(e) =>
                                                        setHasAcceptedTerms(
                                                            e.target.checked
                                                        )
                                                    }
                                                    checked={hasAcceptedTerms}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {eventData.prices &&
                                        Object.entries(eventData.prices).map(
                                            ([key, value]) => {
                                                if (
                                                    !value ||
                                                    typeof value !== "number"
                                                ) {
                                                    return null;
                                                }

                                                const keyTranslations = {
                                                    fullPrice: "Inteira",
                                                    halfEntry: "Meia-entrada",
                                                    advancePrice:
                                                        "Pagamento Antecipado",
                                                    promotionalPrice:
                                                        "Preço Promocional",
                                                };

                                                const label =
                                                    keyTranslations[key] || key;
                                                const linkKey = `${key}Link`;
                                                const link =
                                                    eventData.prices[linkKey];

                                                return (
                                                    <div>
                                                        <Row
                                                            className="mb-2"
                                                            key={key}
                                                        >
                                                            <Col
                                                                xs={12}
                                                                className="d-flex justify-content-center"
                                                            >
                                                                <ButtonCallToACtion
                                                                    btnText={
                                                                        eventData.typeOfEvent !==
                                                                        "show"
                                                                            ? `INSCREVER-SE COMO ${label}`
                                                                            : "CONFIRMAR MINHAS TURMAS"
                                                                    }
                                                                    className={
                                                                        "big-button payment-button"
                                                                    }
                                                                    type="submit"
                                                                    onClick={() => {
                                                                        const paymentLink =
                                                                            link ||
                                                                            eventData
                                                                                .prices
                                                                                .fullPriceLink;
                                                                        setOrder(
                                                                            (
                                                                                prevOrder
                                                                            ) => ({
                                                                                ...prevOrder,
                                                                                event: eventData._id,
                                                                                type: key,
                                                                                paymentLink,
                                                                            })
                                                                        );
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            }
                                        )}
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {error && (
                                    <div className="d-flex justify-content-center">
                                        <Alert
                                            variant={"danger"}
                                            className="mb-0 text-center small w-auto"
                                        >
                                            <strong>{errorMessage}</strong>
                                        </Alert>
                                    </div>
                                )}
                                {errorMessage ===
                                    "Você já está cadastrado nesse evento." &&
                                    eventData.typeOfEvent === "show" && (
                                        <Row className="mt-3">
                                            <Col
                                                xs={12}
                                                className="text-center"
                                            >
                                                <p>
                                                    Caso não tenha concluído o
                                                    pagamento,{" "}
                                                    <a
                                                        href={
                                                            eventData.prices
                                                                ?.fullPriceLink
                                                        }
                                                        className="font-semi-bold"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        clique aqui{" "}
                                                    </a>
                                                    e faça!
                                                </p>
                                            </Col>
                                        </Row>
                                    )}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            <Loading show={load} />
        </>
    );
}

export default EventsUsers;
