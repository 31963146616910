import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap"
import MaterialIcon from 'material-icons-react';

export default class WidgetNumber extends Component {
    render() {
        return (
            <Link to={this.props.link} className={'widget-number-link'}>
                <Card className="widget-number">
                    <Card.Body>
                        <Row>
                            <Col xs={3} className="d-flex align-items-center justify-content-center">
                                <MaterialIcon icon={this.props.icon} />
                            </Col>
                            <Col xs={9} className="d-flex align-items-start flex-column">
                                <h5 className="d-block fw-bold color-primary" title={this.props.title}>{this.props.title}</h5>
                                <h4 className="d-block fw-bold color-purple mb-0">{this.props.value}</h4>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Link>
        );
    }
}

