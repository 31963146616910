import React, { useState, useEffect, useCallback } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Dropdown,
    Modal,
    Form,
    Alert,
} from "react-bootstrap";
import Swal from "sweetalert2";
import Sidebar from "../../../components/Sidebar";
import {
    CardList,
    CardListHeader,
    CardListHeaderItem,
    CardListHeaderSortItem,
    CardListBody,
    CardListBodyItem,
    CardListBodyItemOptions,
} from "../../../components/CardList";
import { ButtonPrimary } from "../../../components/Buttons/index.js";
import Loading from "../../../components/Loading";
import MaterialIcon from "material-icons-react";
import api from "../../../config/api.js";
import { dateHourFormat, debounce } from "../../../config/utils";

function AdminUsers() {
    const [load, setLoad] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [sort, setSort] = useState({ sortBy: "createdAt", order: "desc" });
    const [page, setPage] = useState(1);
    const [dataInfo, setDataInfo] = useState([]);
    const [data, setData] = useState([]);
    const [adminUser, setAdminUser] = useState({});
    const [showModalView, setShowModalView] = useState(false);
    const [showModalEditAdd, setshowModalEditAdd] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [statusValue, setStatusValue] = useState("all");
    const [limitValue, setLimitValue] = useState(20);

    const getData = useCallback(
        async (page = 1) => {
            setLoad(true);
            try {
                const response = await api.get(
                    `/admin/users?paginate=true&page=${page}&sortBy=${sort.sortBy}&order=${sort.order}&limit=${limitValue}&search=${searchValue}&status=${statusValue}`
                );
                const { docs, ...dataInfo } = response.data;
                setData(docs);
                setDataInfo(dataInfo);
            } catch (error) {
                console.log(error);
            } finally {
                setLoad(false);
            }
        },
        [sort, limitValue, searchValue, statusValue]
    );

    useEffect(() => {
        document.title = "JS Studio de Dança - Usuários Admin";
        getData();
    }, [getData]);

    const handleInputChange = async (event) => {
        const { name, value } = event.target;
        setAdminUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const handleSort = (sortKey) => {
        setSort((prevSort) => {
            const isAsc =
                prevSort.sortBy === sortKey && prevSort.order === "asc";
            return {
                sortBy: sortKey,
                order: isAsc ? "desc" : "asc",
            };
        });
    };

    const startSubmitProcess = () => {
        setError(false);
        setErrorMessage("");
        setLoad(!load);
    };

    const prevPage = () => {
        if (page === 1) return;
        const pageNumber = page - 1;
        setPage(pageNumber);
        getData(pageNumber);
    };

    const nextPage = () => {
        if (page === dataInfo.pages) return;
        const pageNumber = page + 1;
        setPage(pageNumber);
        getData(pageNumber);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        startSubmitProcess();

        if (adminUser._id) {
            await api
                .put(`/admin/users/${adminUser._id}`, adminUser)
                .then(() => {
                    setLoad(false);
                    setError(false);
                    setshowModalEditAdd(false);
                    getData(page);
                    Swal.fire({
                        icon: "success",
                        title: "Sucesso!",
                        text: "Usuário editado com sucesso.",
                    });
                })
                .catch((err) => {
                    setLoad(false);
                    setError(true);
                    setErrorMessage(err.response.data.error);
                });
        } else {
            await api
                .post("/admin/users", adminUser)
                .then(() => {
                    setLoad(false);
                    setError(false);
                    setshowModalEditAdd(false);
                    getData(page);
                    Swal.fire({
                        icon: "success",
                        title: "Usuário cadastrado!",
                        text: "Informe os dados para que ele possa acessar.",
                    });
                })
                .catch((err) => {
                    setLoad(false);
                    setError(true);
                    setErrorMessage(err.response.data.error);
                });
        }
    };

    const deleteData = async (admin, remove) => {
        Swal.fire({
            icon: "info",
            title: "Atenção!",
            text: `Deseja ${
                remove
                    ? "remover"
                    : admin.status === "active"
                    ? "inativar"
                    : "ativar"
            } o usuário administrativo? ${
                remove && "Essa ação não pode ser desfeita."
            }`,
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            dangerMode: true,
        }).then(async (res) => {
            if (res.isConfirmed) {
                setLoad(true);
                await api
                    .delete(`/admin/users/${admin._id}`, {
                        data: { remove },
                    })
                    .then(() => {
                        setLoad(false);
                        setError(false);
                        getData(page);
                        Swal.fire({
                            icon: "success",
                            title: "Sucesso!",
                            text: `Usuário administrativo ${
                                remove
                                    ? "removido"
                                    : admin.status === "active"
                                    ? "inativado"
                                    : "ativado"
                            } com sucesso.`,
                        });
                    })
                    .catch((err) => {
                        setLoad(false);
                        setError(true);
                        setErrorMessage(err.response.data.error);
                        Swal.fiew({
                            icon: "error",
                            title: "Erro!",
                            text: `Erro ao ${
                                admin.status === "active"
                                    ? "inativar"
                                    : "ativar"
                            } o usuário administrativo, tente novamente mais tarde.`,
                        });
                    });
            }
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSetSearchValue = useCallback(
        debounce(setSearchValue, 20),
        []
    );

    const handleInputSearch = (event) => {
        const { name, value } = event.target;

        if (name === "search") {
            debouncedSetSearchValue(value);
        }
        if (name === "status") {
            setStatusValue(value);
        }
        if (name === "limit") {
            setLimitValue(value);
        }
    };

    return (
        <>
            <Sidebar
                pageName="Usuários Admin"
                pageUrl="/dashboard/admin-usuarios"
            >
                <Container fluid>
                    <Row className="m-2 justify-content-end">
                        <Col xs={12} sm={3} className="text-right">
                            <small>Pesquisar por nome</small>
                            <input
                                type="text"
                                className="form-control input-search"
                                name="search"
                                value={searchValue}
                                onChange={handleInputSearch}
                            />
                        </Col>
                        <Col xs={12} sm={2} className="text-right">
                            <small>Filtrar por status</small>
                            <select
                                className="form-control input-search"
                                name="status"
                                value={statusValue}
                                onChange={handleInputSearch}
                            >
                                <option value="" disabled>
                                    Filtrar por status
                                </option>
                                {[
                                    {
                                        name: "Todos",
                                        value: "all",
                                    },
                                    {
                                        name: "Ativos",
                                        value: "active",
                                    },
                                    {
                                        name: "Inativos",
                                        value: "inactive",
                                    },
                                ].map((item, index) => (
                                    <option value={item.value} key={index}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col xs={12} sm={1}>
                            <small># itens</small>
                            <select
                                className="form-control input-search"
                                name="limit"
                                value={limitValue}
                                onChange={handleInputSearch}
                            >
                                <option value="" disabled>
                                    Selecionar limite
                                </option>
                                {[
                                    {
                                        name: "5",
                                        value: 5,
                                    },
                                    {
                                        name: "10",
                                        value: 10,
                                    },
                                    {
                                        name: "20",
                                        value: 20,
                                    },
                                    {
                                        name: "40",
                                        value: 40,
                                    },
                                    {
                                        name: "80",
                                        value: 80,
                                    },
                                    {
                                        name: "100",
                                        value: 100,
                                    },
                                ].map((item, index) => (
                                    <option value={item.value} key={index}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col
                            xs={12}
                            sm={2}
                            className="d-flex align-items-end justify-content-end"
                        >
                            <Button
                                className="custom-btn btn-app btt-add"
                                onClick={() => {
                                    setshowModalEditAdd(true);
                                    setAdminUser({});
                                }}
                            >
                                Adicionar usuário
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CardList
                                page={parseInt(dataInfo.page)}
                                pages={dataInfo.pages}
                                data={data}
                                callbackNext={nextPage}
                                callbackPrev={prevPage}
                            >
                                <CardListHeader className="bg-color-light-gray">
                                    <Row>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={3}
                                            onSort={handleSort}
                                            sortKey={"name"}
                                        >
                                            Nome
                                        </CardListHeaderSortItem>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={3}
                                            onSort={handleSort}
                                            sortKey={"email"}
                                        >
                                            E-mail
                                        </CardListHeaderSortItem>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={2}
                                            onSort={handleSort}
                                            sortKey={"createdAt"}
                                        >
                                            Data de Criação
                                        </CardListHeaderSortItem>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={2}
                                            onSort={handleSort}
                                            sortKey={"status"}
                                        >
                                            Status
                                        </CardListHeaderSortItem>
                                        <CardListHeaderItem
                                            xs={12}
                                            lg={2}
                                            className="text-center"
                                        >
                                            Gerenciar
                                        </CardListHeaderItem>
                                    </Row>
                                </CardListHeader>

                                {data.map((data, index) => (
                                    <CardListBody key={index}>
                                        <Row>
                                            <CardListBodyItem
                                                xs={12}
                                                lg={3}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Nome:"}
                                                value={data.name}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={3}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"E-mail:"}
                                                value={data.email}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Data de Criação:"}
                                                value={dateHourFormat(
                                                    data.createdAt
                                                )}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={2}
                                                className={`d-inline-flex align-items-center small ${
                                                    (data.status === "active" &&
                                                        "fw-bold text-success") ||
                                                    (data.status ===
                                                        "inactive" &&
                                                        "fw-bold text-danger")
                                                }`}
                                                title={"Status:"}
                                                value={
                                                    data.status === "active"
                                                        ? "Ativo"
                                                        : "Inativo"
                                                }
                                            />
                                            <CardListBodyItemOptions
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center justify-content-center"
                                            >
                                                <Dropdown.Item
                                                    className="text-success font-semi-bold text-center"
                                                    onClick={() => {
                                                        setShowModalView(true);
                                                        setAdminUser(data);
                                                    }}
                                                >
                                                    Ver informações
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="text-primary font-semi-bold text-center"
                                                    onClick={() => {
                                                        setshowModalEditAdd(
                                                            true
                                                        );
                                                        setAdminUser(data);
                                                    }}
                                                >
                                                    Editar informações
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="text-danger font-semi-bold text-center"
                                                    onClick={() =>
                                                        deleteData(data)
                                                    }
                                                >
                                                    {data.status === "active"
                                                        ? "Inativar usuário"
                                                        : "Ativar usuário"}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="text-danger font-semi-bold text-center"
                                                    onClick={() =>
                                                        deleteData(
                                                            data,
                                                            true
                                                        )
                                                    }
                                                >
                                                    Remover usuário
                                                </Dropdown.Item>
                                            </CardListBodyItemOptions>
                                        </Row>
                                    </CardListBody>
                                ))}
                                {data.length === 0 ? (
                                    <>
                                        <CardListBody>
                                            <Col
                                                xs={12}
                                                className="d-inline-flex align-items-center text-muted small justify-content-center"
                                            >
                                                Nenhum item encontrado.
                                            </Col>
                                        </CardListBody>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </CardList>
                        </Col>
                    </Row>
                </Container>
            </Sidebar>
            <Modal
                show={showModalView}
                onHide={() => {
                    setShowModalView(false);
                    setAdminUser({});
                }}
                size={"lg"}
            >
                <Modal.Header className="border-0 p-4 d-flex align-items-center bg-color-light-gray">
                    <Modal.Title className="d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-start justify-content-center flex-column ml-3">
                            <h5 className="mb-0 fw-bold color-dark-gray">
                                {adminUser.name}
                            </h5>
                        </div>
                    </Modal.Title>
                    <div style={{ cursor: "pointer" }}>
                        <MaterialIcon
                            icon="close"
                            onClick={() => {
                                setShowModalView(false);
                                setAdminUser({});
                            }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row className="mb-2">
                            <Col xs={12} sm={6}>
                                <h6 className="fw-bold">Nome</h6>
                                <p className="text-muted">{adminUser.name}</p>
                            </Col>
                            <Col xs={12} sm={6}>
                                <h6 className="fw-bold">E-mail</h6>
                                <p className="text-muted">{adminUser.email}</p>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs={12} sm={6}>
                                <h6 className="fw-bold">Status</h6>
                                <p
                                    className={
                                        (adminUser.status === "active" &&
                                            "fw-bold text-success") ||
                                        (adminUser.status === "inactive" &&
                                            "fw-bold text-danger")
                                    }
                                >
                                    {adminUser.status === "active"
                                        ? "Ativo"
                                        : "Inativo"}
                                </p>
                            </Col>
                            <Col xs={12} sm={6}>
                                <h6 className="fw-bold">Role</h6>
                                <p className="text-muted">
                                    {adminUser.role === "admin"
                                        ? "Admin"
                                        : "Usuário"}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container fluid>
                        <Row className="mb-2">
                            <Col xs={12} md={6}>
                                <small className="text-muted">
                                    Criado em:{" "}
                                    <strong>
                                        {dateHourFormat(adminUser.createdAt)}
                                    </strong>
                                    .
                                </small>
                            </Col>
                            <Col
                                xs={12}
                                md={6}
                                className="text-start text-md-end"
                            >
                                <small className="text-muted">
                                    Atualizado em:{" "}
                                    <strong>
                                        {dateHourFormat(adminUser.updatedAt)}
                                    </strong>
                                    .
                                </small>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showModalEditAdd}
                onHide={() => {
                    setshowModalEditAdd(false);
                    setAdminUser({});
                }}
                size={"lg"}
            >
                <Modal.Header className="border-0 p-4 d-flex align-items-center bg-color-light-gray">
                    <Modal.Title className="d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-start justify-content-center flex-column ml-3">
                            <h5 className="mb-0 fw-bold color-dark-gray">
                                {adminUser._id
                                    ? `Editar Usuário`
                                    : "Adicionar Usuário Admin"}
                            </h5>
                        </div>
                    </Modal.Title>
                    <div style={{ cursor: "pointer" }}>
                        <MaterialIcon
                            icon="close"
                            onClick={() => {
                                setshowModalEditAdd(false);
                                setAdminUser({});
                            }}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Form onSubmit={handleSubmit}>
                            <Row className="mb-0 mb-md-3">
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Nome
                                            <sup className="ms-1 text-danger fw-bold">
                                                *
                                            </sup>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            value={adminUser.name}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            E-mail
                                            <sup className="ms-1 text-danger fw-bold">
                                                *
                                            </sup>
                                        </Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={adminUser.email}
                                            onChange={handleInputChange}
                                            required
                                            disabled={
                                                adminUser._id ? true : false
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-0 mb-md-3">
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Senha
                                            {adminUser._id === undefined && (
                                                <sup className="ms-1 text-danger fw-bold">
                                                    *
                                                </sup>
                                            )}
                                        </Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            value={adminUser.password}
                                            placeholder={
                                                adminUser._id &&
                                                "Senha oculta por segurança."
                                            }
                                            onChange={handleInputChange}
                                            required={
                                                adminUser._id === undefined
                                            }
                                        />
                                        <sup className="ms-1 text-danger fw-bold">
                                            Deixe em branco para manter a senha
                                            atual
                                        </sup>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3 mb-md-2">
                                        <Form.Label className="text-uppercase">
                                            Nível de Acesso
                                            <sup className="ms-1 text-danger fw-bold">
                                                *
                                            </sup>
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="role"
                                            value={adminUser.role}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="" disabled>
                                                Selecione o nível
                                            </option>
                                            {[
                                                {
                                                    name: "Administrador",
                                                    value: "admin",
                                                },
                                                {
                                                    name: "Usuário",
                                                    value: "user",
                                                },
                                            ].map((item, index) => (
                                                <option
                                                    value={item.value}
                                                    key={index}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {error && (
                                    <div className="d-flex justify-content-center">
                                        <Alert
                                            variant={"danger"}
                                            className="mb-0 text-center small w-auto"
                                        >
                                            <strong>{errorMessage}</strong>
                                        </Alert>
                                    </div>
                                )}
                                <Col
                                    xs={12}
                                    className="d-flex justify-content-center"
                                >
                                    <ButtonPrimary
                                        btnText={
                                            adminUser._id
                                                ? "salvar usuário"
                                                : "adicionar usuário"
                                        }
                                        type="submit"
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
            <Loading show={load} />
        </>
    );
}

export default AdminUsers;
